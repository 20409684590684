import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { GenericResModel, UserTimeZoneModel } from 'countable@model';
import { BehaviorSubject, map, Observable, of, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import * as moment from 'moment';
import { FirmService } from 'countable@services';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService implements OnDestroy {
    private static readonly userApi = environment.apiV1 + '/user';

    public readonly subject: BehaviorSubject<UserTimeZoneModel> = new BehaviorSubject(null);
    private readonly firmSubject: Subscription;

    constructor(private http: HttpClient, private firmService: FirmService) {
        this.firmSubject = this.firmService.subject.subscribe(firm => {
            if (firm) {
                this.getUserTimeZone().subscribe();
            }
        });
    }

    public getUserTimeZone(): Observable<GenericResModel<UserTimeZoneModel>> {
        return this.http.get<GenericResModel<UserTimeZoneModel>>(TimezoneService.userApi + '/time-zone')
            .pipe(map((response: any) => {
                if (response.status === 200) {
                    this.subject.next(response.data);
                    return response;
                }
            }))
    }

    public getTimezoneList(): Observable<GenericResModel<UserTimeZoneModel>> {
        const getTimeZoneList = (): Observable<any> => {
            return of(StorageService.applicationModel.timeZoneList);
        };
        if (StorageService.applicationModel.timeZoneList) {
            return getTimeZoneList().pipe(map(res => res));
        } else {
            return this.http.get<GenericResModel<UserTimeZoneModel>>(environment.apiV1 + '/time-zone').pipe(map((response: any) => {
                if (response.status === 200) {
                    StorageService.applicationModel.timeZoneList = response;
                    return response;
                }
            }))
        }

    }

    public updateTimeZone(id: number): Observable<GenericResModel<boolean>> {
        return this.http.put<GenericResModel<boolean>>(TimezoneService.userApi + '/time-zone', id);
    }

    public getCurrentUTCDate(): string {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(now.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00`;
    }

    public toUTC(date: Date | string): string {
        // Assuming input date string is in 'YYYY-MM-DD' format
        return moment.utc(date).format();
    }

    public fromUTC(utcDate: string): Date {
        return moment.utc(utcDate).toDate();
    }

    // get number days difference
    public diffInDays(inputDate: Date | string, targetDate: Date | string): number {
        if (!targetDate) targetDate = this.getCurrentUTCDate();
        return moment.duration(moment(inputDate, 'YYYY-MM-DD').diff(moment(targetDate, 'YYYY-MM-DD'))).asDays();
    }

    public checkingSystemAndAppTimeZone() {
        const currentDate = new Date();
        const timezoneOffset = currentDate.getTimezoneOffset(); // in minutes

        // Convert offset to hours and minutes
        const absOffset = Math.abs(timezoneOffset);
        const hours = Math.floor(absOffset / 60);
        const minutes = absOffset % 60;

        // Format the offset string
        const sign = timezoneOffset > 0 ? '-' : '+';
        const formattedOffset = `UTC${sign}${this.pad(hours)}:${this.pad(minutes)}`;
        return formattedOffset;

    }

    private pad(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }

    ngOnDestroy(): void {
        this.firmSubject && this.firmSubject.unsubscribe();
    }
}
