import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { bot } from './app-config';

// declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: "root"
})
export class EncodingService {

  recognition : any;
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords: any;

  constructor() {
    this.initializeSpeechRecognition();
  }

  private initializeSpeechRecognition() {
    // Check if webkitSpeechRecognition or SpeechRecognition is available
    const SpeechRecognition = (window as any).webkitSpeechRecognition || (window as any).SpeechRecognition;

    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
    } else {
      console.warn('Speech recognition not supported in this browser.');
      this.recognition = null; // No speech recognition support
    }
  }


  public enData(data, type?) {
    const encryptedBase64Key = btoa(this.botConversion()); // "U0Ajc2MlI20xMjk4LTEyIyQtMXJneFplZEUtOTAsIyE=";
    const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    try {
      const enc = CryptoJS.AES.encrypt(JSON.stringify(data), parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      if (type === undefined) {
        return enc.split('/').join(',');
      } else {
        return enc;
      }

    } catch (e) {
      // console.log(e);
    }
  }

  public deData(data) {
    const encryptedBase64Key = btoa(this.botConversion());
    const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    try {
      return CryptoJS.AES.decrypt(data, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
    } catch (e) {
      // console.log(e);
    }
  }

  botConversion() {
    const botConversion = atob(bot);
    const botSplit = botConversion.split('-');
    botSplit.shift();
    botSplit.pop();
    botSplit.splice(2, 1);
    return botSplit.join('-');
  }

  init() {
    if (!this.recognition) {
      console.warn('Speech recognition is not initialized.');
      return;
    }
    this.recognition.interimResults = true;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e: any) => {
      const transcript = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      console.log(transcript);
    });
  }

  start() {
    if (!this.recognition) {
      console.warn('Speech recognition is not initialized.');
      return;
    }
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    console.log('Speech recognition started');
    this.recognition.addEventListener('end', (condition: any) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
        console.log('End speech recognition');
      } else {
        this.wordConcat();
        this.recognition.start();
      }
    });
  }

  stop() {
    if (!this.recognition) {
      console.warn('Speech recognition is not initialized.');
      return;
    }
    this.isStoppedSpeechRecog = true;
    this.wordConcat();
    this.recognition.stop();
    console.log('End speech recognition');
  }

  wordConcat() {
    this.text = this.text + ' ' + this.tempWords + '.';
    this.tempWords = '';
  }
}
