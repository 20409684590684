import { Pipe, PipeTransform } from '@angular/core';
import { UserTimeZoneModel } from 'countable@model';
import { TimezoneService } from 'src/app/services/timezone.service';

@Pipe({
  name: 'appCountableTimeZone',
  standalone: true
})
export class CountableTimeZonePipe implements PipeTransform {
  timeZone: string = 'UTC';
  selectedTimeZone: UserTimeZoneModel;

  constructor(public timezoneService: TimezoneService) {
    this.timezoneService.subject.subscribe(value => {
      if (value) {
        this.timeZone = value.conversionNameAngular;
        this.selectedTimeZone = value;
      }
    })
  }

  transform(value: string | Date | number, dateFormat: string = 'MMM dd, yyyy hh:mm a', timeZoneValue?: string, isPreviousYear?: boolean): string {
    let parsedDate: any;
    timeZoneValue ? (timeZoneValue === 'UTC' ? timeZoneValue = 'Etc/GMT' : '') : timeZoneValue = this.timeZone;
    if (typeof value === 'string') {
      if (value.includes('T')) {
        parsedDate = (value.endsWith('Z') || value.includes('.')) ? new Date(value) : new Date(value + '.000Z');
      } else {
        parsedDate = new Date(value + ' UTC');
      }
    } else if (typeof value === 'number') {
      parsedDate = new Date(value);
    } else {
      parsedDate = value;
    }

    // Check if the parsed date is valid
    if (isNaN(parsedDate?.getTime())) {
      return '';
    }


    // Convert the date to the target timezone and format it
    let convertedDate: Date;
    if (typeof value === 'object') {
      convertedDate = new Date(parsedDate.toLocaleString('en-US', { timeZone: timeZoneValue, hour12: true }));
    } else {
      if ((typeof value === 'string' && value.includes('T') && value.includes('.'))) {
        const date = new Date(value);
        const options: Intl.DateTimeFormatOptions = {
          timeZone: timeZoneValue,
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        convertedDate = new Date(formatter.format(date));
      } else {
        convertedDate = new Date(parsedDate.toLocaleString('en-US', { timeZone: timeZoneValue, hour12: true }));
      }
    }

    // for previous year
    if (isPreviousYear) {
      const lastYearDate = convertedDate;
      lastYearDate.setFullYear(convertedDate.getFullYear() - 1);
      convertedDate = lastYearDate;
    }

    // return parsedDate.toLocaleString('en-US', options);
    // Get month name
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const fullMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const month = monthNames[convertedDate.getMonth()];
    const fullMonth = fullMonthNames[convertedDate.getMonth()];

    // Get day, year, hour, minute, and period (AM/PM)
    const day = convertedDate.getDate().toString().padStart(2, '0');
    const monthNumber = (convertedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = convertedDate.getFullYear();
    let hour = convertedDate.getHours();
    const minute = convertedDate.getMinutes();
    const seconds = convertedDate.getSeconds();
    const period = hour < 12 ? 'AM' : 'PM';
    hour = hour % 12 || 12; // Convert 0 to 12 for AM
    switch (dateFormat) {
      case 'MMM DD, yyyy':
      case 'MMM d, yyyy':
      case 'MMM d, y':
      case 'MMM dd, yyyy': //Aug 7, 2024
        return `${month} ${day}, ${year}`;
        break;
      case 'YYYY-MM-DD':
        return `${year}-${monthNumber}-${day}`;
        break;
      case 'DD/MM/YY':
        return `${day}/${monthNumber}/${year.toString().slice(-2)}`;
        break;
      case 'MM/dd/yyyy':
        return `${monthNumber}/${day}/${year}`;
        break;
      case 'MMM d yyyy':
        return `${month} ${day} ${year}`;
        break;
      case 'MMMM d, yyyy': //August 7, 2024
        return `${fullMonth} ${day}, ${year}`;
        break;
      case 'MMMM d yyyy': //August 7 2024
        return `${fullMonth} ${day} ${year}`;
        break;
      case 'MMMM dd, yyyy hh:mm a': //August 7, 2024 02:34 am
        return `${fullMonth} ${day}, ${year} ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
        break;
      case 'MMMddyyyy':
        return `${month}${day}${year}`;
        break;
      case 'MMMM d': //August 7
        return `${fullMonth} ${day}`;
        break;
      case 'yyyy': //2024
        return `${year}`;
        break;
      case 'HH:mm a':
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
        break;
      case 'dd MMM yyyy, hh:mm a':
        return `${day} ${month} ${year}, ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
        break;
      case 'MMM dd, yyyy - hh:mm a':
        return `${month} ${day}, ${year} - ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
        break;
      case 'MMM dd, yyyy hh:mm a':
        return `${month} ${day}, ${year} ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
        break;
      default:
        break;
    }

  }

  public convertUTCToOffsetFormat(utcDate) {
    const date = new Date(utcDate + ' UTC');
    // Calculate the offset in milliseconds
    const offsetMilliseconds = this.parseOffsetToMilliseconds('+00:00');
    // Adjust the date by the offset
    const adjustedDate = new Date(date.getTime() - offsetMilliseconds);
    const convertedDate = new Date(adjustedDate.toLocaleString('en-US', { timeZone: 'UTC', hour12: true }));
    const day = convertedDate.getDate().toString().padStart(2, '0');
    const monthNumber = (convertedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = convertedDate.getFullYear();
    return `${year}-${monthNumber}-${day}T00:00:00Z`;
  }


  private parseOffsetToMilliseconds(offset) {
    const [sign, hours, minutes] = offset.match(/([+-])(\d{2}):?(\d{2})/).slice(1);
    const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
    return (sign === '+' ? -1 : 1) * totalMinutes * 60 * 1000; // Convert to milliseconds
  }

  public getCurrentUTCDate(): string {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(now.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00Z`;
  }



}