import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResModel, ProceduresNotesModel, ProceduresNotesReqModel } from 'countable@model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TbAccountReqModel } from '../model/tb/account/tb-account-req.model';
import { TbAccountResModel } from '../model/tb/account/tb-account-res.model';
import { JournalReqModel } from '../model/tb/journal/journal-req.model';
import { JournalResModel } from '../model/tb/journal/journal-res.model';
import { TrialBalanceModel } from '../model/tb/trial-balance.model';

@Injectable({
  providedIn: 'root'
})
export class TbApiService {

  private static readonly tbBaseUri = environment.apiV1 + '/tb';
  private static readonly multiMapping = environment.apiV1 + '/tb/multi-mapping';
  private static readonly leadSheetCode = '/leadsheet-code/';

  constructor(private httpClient: HttpClient) {}

  refreshTrialBalance(engagementId: number): Observable<boolean> {
    return this.httpClient.post<boolean>(TbApiService.tbBaseUri + '/refresh', engagementId, {responseType: 'json'});
  }

  getTrialBalance(engagementId: number, year: number): Observable<GenericResModel<TrialBalanceModel[]>> {
    return this.httpClient.post<GenericResModel<TrialBalanceModel[]>>(TbApiService.tbBaseUri + '/get', {
      id: engagementId,
      year: year
    });
  }

  public createAccount(data: TbAccountReqModel): Observable<TbAccountResModel> {
    return this.httpClient.post<TbAccountResModel>(TbApiService.tbBaseUri + '/account/add', data, {responseType: 'json'});
  }

  public validateCode(data: TbAccountReqModel): Observable<TbAccountResModel> {
    return this.httpClient.post<TbAccountResModel>(TbApiService.tbBaseUri + '/account/validate-code', data, {responseType: 'json'});
  }

  public validateName(data: TbAccountReqModel): Observable<TbAccountResModel> {
    return this.httpClient.post<TbAccountResModel>(TbApiService.tbBaseUri + '/account/validate-name', data, {responseType: 'json'});
  }

  public pushJournalEntry(entry: JournalReqModel): Observable<GenericResModel<JournalResModel>> {
    return this.httpClient.put<GenericResModel<JournalResModel>>(TbApiService.tbBaseUri + '/journal/add-entry', entry, {responseType: 'json'});
  }

  public validateEntry(entry: JournalReqModel): Observable<GenericResModel<JournalResModel>> {
    return this.httpClient.post<GenericResModel<JournalResModel>>(TbApiService.tbBaseUri + '/journal/validate-entry', entry, {responseType: 'json'});
  }

  public importTb(data: any): Observable<any> {
    return this.httpClient.post(TbApiService.tbBaseUri + '/import', data, {responseType: 'json'});
  }

  public getProcedureNotes(data: ProceduresNotesReqModel): Observable<GenericResModel<ProceduresNotesModel>> {
    return this.httpClient.post<GenericResModel<ProceduresNotesModel>>(environment.apiV1 + '/procedures/notes/get', data);
  }

  public getLastUpdatedTbInfo(engagementId: number): Observable<GenericResModel<string>> {
    return this.httpClient.get<GenericResModel<string>>(environment.apiV1 + `/tb/refresh-timestamp/${engagementId}`);
  }

  public saveMultiMapping(data: any) {
    return this.httpClient.put<GenericResModel<JournalResModel>>(TbApiService.multiMapping, data, {responseType: 'json'});
  }

  public getLeadSheetCode(engagementId: number, year: number): Observable<any> {
    return this.httpClient.get(environment.apiV1 + TbApiService.leadSheetCode + engagementId + '/' + year);
  }
}

