import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import 'jspdf-autotable';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ExportModel } from '../../../model/export.model';
import { ApiService } from '../../../services/api.service';
import { SharedService } from '../../shared/shared.service';
import { CountableTimeZonePipe } from 'countable@helpers';

@Component({
  selector: 'app-daily-loads',
  templateUrl: './daily-loads.component.html',
  styleUrls: ['./daily-loads.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class DailyLoadsComponent implements OnInit {

  @ViewChild('rejectDialog') rejectDialog: TemplateRef<any>;
  @ViewChild('acceptDialog') acceptDialog: TemplateRef<any>;
  @ViewChild('balanceDialogBox') balanceDialogBox: TemplateRef<any>;
  loggedInUserData: any;
  useraccountid: any;
  dailyLoadData: any;
  engagementID: string;
  trialbalanceData: any = [];
  datesData: any = [];
  trialBalYear: any;
  recentStatus: any;
  selectedEngagementDataCopy: any;
  engagementName: any;
  clientName: any;
  yearEnd: any;
  originaltotal: any;
  revisedtotal: any;
  changetotal: any;
  removeBarcketsPattern = /[(),]/g;
  selectedEntityName: any;
  selectedYear: any;
  dailyLoadChangesStatu: any;
  trailBalanceDetailsCopy: any;
  exportDailyLoadData: any = [];
  balReconciledTitle = 'Trial Balance not Reconciled';
  lasupdatedate: any;
  dailyLoadChangesStatuSubScription: any;
  isTblFooter: boolean = false;
  shortCutMenuDetailsObj: any;
  accessPermissions: any = {};
  loadData: any;
  filteredEmail: any = [];

  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {
    if (ev.key == 'dailyLoadChangesLoaded') {
      this.trailBalanceDetailsCopy = JSON.parse((localStorage.getItem('trialBalanceObj')));
    }

  }

  constructor(public router: Router, public dialog: MatDialog, private apiservice: ApiService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,private countableTimeZonePipe: CountableTimeZonePipe) { }

  rejectLoad(): void {
    const dialogRef = this.dialog.open(this.rejectDialog, {
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  acceptLoad(): void {
    const dialogRef = this.dialog.open(this.acceptDialog, {
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnInit() {
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
    this.shortCutMenuDetailsObj = JSON.parse((localStorage.getItem('shortCutMenuDetails')));
    if (this.loggedInUserData && this.loggedInUserData.useracctid) {
      this.useraccountid = this.loggedInUserData.useracctid;
    }
    this.selectedEngagementDataCopy = JSON.parse((localStorage.getItem('selectedEngagementObjCopy')));
    this.engagementName = this.selectedEngagementDataCopy.engagementName
    this.clientName = this.selectedEngagementDataCopy.clientName
    this.yearEnd = this.selectedEngagementDataCopy.yearEnd
    this.engagementID = localStorage.getItem('engagementID');
    this.trialBalYear = localStorage.getItem('trailBalYear');
    this.getDailyLoadData()
    this.selectedEntityName = this.selectedEngagementDataCopy.clientName;
    this.selectedYear = this.countableTimeZonePipe.transform(this.selectedEngagementDataCopy.yearEnd, 'MMM dd, yyyy', 'UTC');

    this.getShowHideUserPermissions(this.loggedInUserData.useracctid);
  }

  displayedColumns: string[] = ['account', 'description', 'leadsheet'];

  displayedColumns1: string[] = ['original', 'revised', 'change'];

  getDailyLoadData() {
    let inputData = { "engagementid": this.engagementID, "acctyear": this.trialBalYear }
    let inputString = JSON.stringify(inputData);
    let data = {
      "procedureName": "gettrailbalancedailyloadfromui",
      "inputParameters": inputString
    }

    this.isTblFooter = this.isTblFooter ? false : false;
    this.spinner.show();
    this.apiservice.getData(data).subscribe(response => {
      this.spinner.hide();
      if (response) {
        let data = JSON.parse(response);
        if (data) {
          this.trailBalanceDetailsCopy = JSON.parse((localStorage.getItem('trialBalanceObj')));

          if (this.router.url === '/dailyload-details') {
            this.dailyLoadChangesStatu = +this.shortCutMenuDetailsObj.tdlchanges;
          }
          else {
            this.dailyLoadChangesStatu = +this.trailBalanceDetailsCopy[0].tdlchanges;
          }

          localStorage.removeItem("dailyLoadChangesLoaded");
          this.dailyLoadData = data[0];
          this.trialbalanceData = this.dailyLoadData.trialbalance;
          this.datesData = this.dailyLoadData.datewisedata;
          //this.datesData = _.sortBy(this.datesData, 'dbrecieveddate').reverse();
          this.recentStatus = this.datesData[0].auditorstatus;
          this.lasupdatedate = this.dailyLoadData.lasupdatedate[0].lasupdatedate;

          if (this.datesData[0].originaltotal) {
            var totalOriginal = this.datesData[0].originaltotal.replace(this.removeBarcketsPattern, '');
          }
          if (this.datesData[0].revisedtotal) {
            var totalRevised = this.datesData[0].revisedtotal.replace(this.removeBarcketsPattern, '');
          }
          if (this.datesData[0].chngtotal) {
            var totalChange = this.datesData[0].chngtotal.replace(this.removeBarcketsPattern, '');
          }

          this.originaltotal = +totalOriginal ? totalOriginal : 0;
          this.revisedtotal = +totalRevised ? totalRevised : 0;
          this.changetotal = +totalChange ? totalChange : 0;

          if (this.dailyLoadChangesStatu === 1) {
            if (this.recentStatus == 1 && this.revisedtotal != 0) {
              this.balanceDialog()
            }
          }
        }
        this.isTblFooter = true;
      }
    }, error => {
      this.spinner.hide();
      this.toastr.warning(error.error)
    })
  }

  rejectDialogOpen() {
    const dialogRef = this.dialog.open(this.rejectDialog, {});
    dialogRef.afterClosed().subscribe(result => { });
  }

  saveResponse(status) {
    let dbrecieveddate
    if (this.datesData && this.datesData.length > 0) {
      dbrecieveddate = this.datesData[0].dbrecieveddate;
    }
    let inputData = {
      "pengagementid": this.engagementID ? parseInt(this.engagementID) : 0,
      "ploginid": this.useraccountid,
      "pauditorstatus": status,// Accept or Reject
      "preceiveddate": dbrecieveddate
    };
    let inputString = JSON.stringify(inputData);
    let data = {
      "procedureName": "updatetrailbalanceauditorstatus",
      "inputParameters": inputString
    }
    this.spinner.show();
    this.apiservice.getData(data).subscribe(response => {
      this.loadData = JSON.parse(response);
      this.loadData.filter((ele) => {
        if (ele.name) {
          this.filteredEmail.push({ id: ele.name });
        }
      });
      localStorage.setItem('dailyLoadChangesLoaded', "success")
      this.spinner.hide();
      if (status === 5) { //Accept
        this.dailyLoadChangesStatu = 5;
        //this.dailyLoadMailSend(this.filteredEmail, this.dailyLoadChangesStatu);
        this.toastr.success('Trial Balance Changes have been accepted');
        localStorage.setItem('TBChangeAccepted-ES',"success");
      }
      else if (status === 6) { //Reject
        this.dailyLoadChangesStatu = 6;
        //this.dailyLoadMailSend(this.filteredEmail, this.dailyLoadChangesStatu);
        this.toastr.success('Trial Balance changes have been rejected');
      }

      if (this.router.url != '/dailyload-details') {
        this.router.navigate(['/dashboard/ntrdashboard/TrialBalance']);
        this.sharedService.refreshShortCutButtons.next(true);
      }
      else {
        localStorage.setItem('tbchangesaction', "success");
        setTimeout(() => {
          window.opener = self;
          window.close();
        }, 1000);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error('Error in saving');
    })
  }
  /*
  --REMOVED--
  dailyLoadMailSend(mailData, status){
    this.spinner.show();
    let data: any;
    let mailObj = {
      "save": mailData
    }
    let mailInfo =  JSON.stringify(mailObj);
      if(status == 5){
         data = {
          "engagementsid":this.engagementID,
          "engagementName":this.selectedEngagementDataCopy.engagementName,
          "toMail": mailInfo,
          "accessLink": this.loadData[0].accesslink,
          "status":"true"
      }
      }else if(status == 6){
       data = {
        "engagementsid":this.engagementID,
        "engagementName":this.selectedEngagementDataCopy.engagementName,
        "toMail": mailInfo,
        "accessLink": this.loadData[0].accesslink,
        "status":"false"
    }
  }
      this.apiservice.dailyLoadMail(data).subscribe(response =>{
      this.spinner.hide();
        if (this.router.url != '/dailyload-details') {
          this.router.navigate(['/dashboard/ntrdashboard/TrialBalance']);
        }
        else {
          localStorage.setItem('tbchangesaction', "success");
          setTimeout(() => {
            window.opener = self;
            window.close();
          }, 1000);
        }
      }, error =>{
        this.spinner.hide();
        this.toastr.error('Error in saving');
        })
  }
  */

  viewTrialBalance() {
    this.router.navigate(['/dashboard/ntrdashboard/TrialBalance']);
  }

  balanceDialog(): void {
    const dialogRef = this.dialog.open(this.balanceDialogBox, {
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //
  exportToPdf() {
    // let inputData = { "engagementid": this.engagementID, "acctyear": this.trialBalYear }
    // let inputString = JSON.stringify(inputData);
    // let data = {
    //   "procedureName": "exporttbdailyload",
    //   "inputParameters": inputString
    // }
    // this.spinner.show();
    // this.apiservice.getData(data).subscribe(response => {
    //   if (response) {
    //     let data = JSON.parse(response);
    //     if (data) {
    //       this.exportDailyLoadData = data;

    //       if (this.exportDailyLoadData) {
    //         let startX = 105;
    //         const fontSize = 12;
    //         let engagementDetails: any = this.selectedEngagementDataCopy;
    //         let engagementName = engagementDetails ? engagementDetails.engagementName : '';
    //         let yearEndDate = this.selectedEngagementDataCopy.yearEnd;
    //         let reportDate = '';
    //         if (yearEndDate) {
    //           let endDate = this.datePipe.transform(yearEndDate, "MMM d, ");
    //           reportDate = endDate + this.trialBalYear;
    //         }
    //         var doc = new jsPDF();

    //         doc.setFont('helvetica')
    //         doc.setFontType('bold');
    //         doc.setFontSize(10)
    //         doc.text('Entity Name: ' + engagementName, 105, 20, { align: 'center' });
    //         doc.setFont('helvetica')
    //         doc.setFontType('normal')
    //         doc.setFontSize(10)
    //         doc.text('As of ' + reportDate, 105, 27, { align: 'center' })

    //         doc.setFont('helvetica')
    //         doc.setFontType('normal')
    //         doc.setFontSize(10)
    //         doc.text('Trial Balance Change Summary', 105, 34, { align: 'center' })
    //         this.exportDailyLoadData.forEach(x => {
    //           x['recieveddate'] = this.datePipe.transform(x['recieveddate'], "M-d-y");

    //         });

    //         var columns = [
    //           { title: "Acc.No", dataKey: "accno" },
    //           { title: "Description", dataKey: "description" },
    //           { title: "Leadsheet", dataKey: "leadsheet" },
    //           { title: "Received", dataKey: "recieveddate" },
    //           { title: "Final", dataKey: "originalbalance" },
    //           { title: "Revised", dataKey: "revised" },
    //           { title: "Change", dataKey: "change" },
    //           { title: "Status", dataKey: "auditorstatus" },
    //         ]
    //         let newDailyLoadDetails = this.exportDailyLoadData;
    //         const totalPagesExp = "{total_pages_count_string}";
    //         doc.autoTable(columns, newDailyLoadDetails, {
    //           margin: { horizontal: 5 },
    //           startY: 40,
    //           styles: {
    //             fontSize: 7,
    //             minCellWidth: 16
    //           },
    //           columnStyles: {
    //             accno: { cellWidth: 10 },
    //           },

    //           didDrawPage: newDailyLoadDetails => {
    //             let footerStr = "Page " + doc.internal.getNumberOfPages();
    //             if (typeof doc.putTotalPages === 'function') {
    //               footerStr = footerStr + " of " + totalPagesExp;
    //             }

    //             doc.setFont('helvetica');
    //             doc.setFontType('normal');
    //             doc.setFontSize(10);
    //             doc.text(footerStr, 190, doc.internal.pageSize.height - 7);
    //           }
    //         });

    //         if (typeof doc.putTotalPages === 'function') {
    //           doc.putTotalPages(totalPagesExp);
    //         }

    //         doc.save('TrailbalanceDailyLoad.pdf');
    //       }
    //     }
    //   }
    //   this.spinner.hide();
    // }, error => {
    //   this.spinner.hide();
    //   this.toastr.warning(error.error)
    // })
  }

  exportTBCheckPdf() {
    const Obj : ExportModel = new ExportModel();
    Obj.id = +this.engagementID;
    Obj.year = this.trialBalYear;
    const fileName = 'TrailbalanceDailyLoad.Pdf';
    this.spinner.show();
    this.sharedService.exportService.exportTBCheck(Obj,fileName).subscribe({
      next: response => {
        if(response){
          // saveAs(response,fileName);
          this.toastr.success('Download Successfull')
        } else {
          this.toastr.error('Download Failed')
        }
      },
      error: error => {
        console.log('Error',error);
      }
    }).add(()=>{
      this.spinner.hide();
    })
  }

  //Get show or hide user permissions
  getShowHideUserPermissions(id) {
    const inputData = { "useraccountid": id };
    let inputString = JSON.stringify(inputData);
    const data = {
      "procedureName": "getshoworhideuserpermissions",
      "inputParameters": inputString
    }
    this.apiservice.getData(data).subscribe(response => {
      if (response) {
        let apdata = [];
        let result = {};
        let keyName = "jjjjj";
        //let stringify = JSON.stringify(response);
        apdata = JSON.parse(response);
        apdata.forEach((x, i) => {
          // result[x.accesstypeitemname.replace(/ /g, '').toLowerCase()] = x.ufid ? true : false;
          if (x.accesstypeitemufi == 1) {
            keyName = 'accessdashboard';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 2) {
            keyName = 'editfirminformation';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 3) {
            keyName = 'billing';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 4) {
            keyName = 'exportdata';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 5) {
            keyName = 'addteammember';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 6) {
            keyName = 'modifyotherteammembers';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 7) {
            keyName = 'deleteteammember';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 8) {
            keyName = 'addclient';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 9) {
            keyName = 'viewclients';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 10) {
            keyName = 'modifyclients';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 11) {
            keyName = 'deleteclient';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 12) {
            keyName = 'viewengagements';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 13) {
            keyName = 'createengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 14) {
            keyName = 'modifyengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 15) {
            keyName = 'deleteengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 16) {
            keyName = 'archiveengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 17) {
            keyName = 'reopenengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 18) {
            keyName = 'viewAllTemplates';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 19) {
            keyName = 'createModifyDeleteOwnTemplates';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 20) {
            keyName = 'ModifyDeleteAllTemplates';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 999) {
            keyName = 'viewmodifiengagement';
            result[keyName] = x.ufid ? true : false;
          }

        });
        //this.accessPermissionsSubject.next(result);
        this.accessPermissions = result;
      }
    }, error => {
      console.log(error.error);
    });
  }
  ngOnDestroy() {
    if (this.dailyLoadChangesStatuSubScription) {
      this.dailyLoadChangesStatuSubScription.unsubscribe();
    }
  }
}
