import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TimezoneService } from 'src/app/services/timezone.service';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CountableTimeZonePipe } from 'src/app/helpers/countable-timezone.pipe';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY"
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "YYYY-MM-DD HH:mm:ss",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-countable-date',
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatTooltipModule, CountableTimeZonePipe],
  templateUrl: './countable-mat-date.component.html',
  styleUrl: './countable-mat-date.component.scss',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    CountableTimeZonePipe
  ]
})
export class CountableMatDateComponent implements OnInit {
  private _inputDate: Date | string = null;
  private _isMinDate: boolean = false;
  private _isMaxDate: boolean = false;
  private _inputMinDate: Date | string = null;
  private _inputMaxDate: Date | string = null;
  private _label: string = null;
  private _subLabel: string = null;
  private _placeholderText: string = null;
  private _isMatActions: boolean = false;
  private _isReadOnly: boolean = false;
  private _isDisabled: boolean = false;
  private _showDateAndFormat: boolean = false;
  private _isRequired: boolean = false;
  private _showInfo: boolean = false;
  private _checkCondition: string = null;
  public displayLabel: string;

  @Input()
  set inputDate(value: Date | string) {
    this._inputDate = value;
    this.onChangeInputDate();
  }

  @Input()
  set isMinDate(value: boolean) {
    this._isMinDate = value;
    this.onChangeInputMinDate();
  }

  @Input()
  set isMaxDate(value: boolean) {
    this._isMaxDate = value;
    this.onChangeInputMaxDate();
  }

  @Input()
  set inputMaxDate(value: Date | string) {
    this._inputMaxDate = value;
    this.onChangeInputMaxDate();
  }

  @Input()
  set inputMinDate(value: Date | string) {
    this._inputMinDate = value;
    this.onChangeInputMinDate();
  }

  @Input()
  set label(value: string) {
    this._label = value;
  }

  get label() {
    return this._label;
  }

  @Input()
  set subLabel(value: string) {
    this._subLabel = value;
  }

  get subLabel() {
    return this._subLabel;
  }

  @Input()
  set placeholderText(value: string) {
    this._placeholderText = value;
  }

  get placeholderText() {
    return this._placeholderText;
  }

  @Input()
  set isMatActions(value: boolean) {
    this._isMatActions = value;
  }

  get isMatActions() {
    return this._isMatActions;
  }

  @Input()
  set isReadOnly(value: boolean) {
    this._isReadOnly = value;
  }

  get isReadOnly() {
    return this._isReadOnly;
  }

  @Input()
  set isDisabled(value: boolean) {
    this._isDisabled = value;
    this._isDisabled ? this.localDate.disable() : this.localDate.enable();
    this.localDate.updateValueAndValidity();
  }

  get isDisabled() {
    return this._isDisabled;
  }

  @Input()
  set showDateAndFormat(value: boolean) {
    this._showDateAndFormat = value;
  }

  get showDateAndFormat() {
    return this._showDateAndFormat;
  }

  @Input()
  set isRequired(value: boolean) {
    this._isRequired = value;
    this._isRequired ? this.localDate.setValidators([Validators.required]) : this.localDate.clearValidators();
    this.localDate.updateValueAndValidity();
  }

  get isRequired() {
    return this._isRequired;
  }

  @Input()
  set showInfo(value: boolean) {
    this._showInfo = value;
  }

  get showInfo() {
    return this._showInfo;
  }

  @Input()
  set checkCondition(value: string) {
    this._checkCondition = value;
    this.onChangeInputDate();
  }

  get checkCondition() {
    return this._checkCondition;
  }

  @Output() selectedDate = new EventEmitter();
  localDate: FormControl<any> = new FormControl;
  maxDate: FormControl<any> = new FormControl;
  minDate: FormControl<any> = new FormControl;
  utcDate: string;
  currentDate = this.dateTimeService.getCurrentUTCDate();

  constructor(private dateTimeService: TimezoneService, private toastr: ToastrService, private countableTimeZonePipe: CountableTimeZonePipe) { }

  ngOnInit(): void {

  }

  private onChangeInputDate() {
    // value  for mat date picker
    if (this._inputDate) {
      if (typeof this._inputDate === 'string') {
        const yearmonthdate = this.validatingDate(this._inputDate, true);
        const formatt = this.dateWithTimeZone(yearmonthdate.split('-')[0], (+yearmonthdate.split('-')[1]) - 1, yearmonthdate.split('-')[2], 0, 0, 0);
        this.localDate.setValue(formatt);
        this.displayLabel = yearmonthdate;
      } else {
        this.localDate.setValue(this.dateTimeService.toUTC(this._inputDate));
        this.displayLabel = this.dateTimeService.toUTC(this._inputDate);
      }

      if (this._checkCondition === 'dueDateIssues') {
        if (this.dateTimeService.diffInDays(this.localDate.value, this.currentDate) < 0) {
          this.localDate.markAsTouched();
          this.selectedDate.emit('error');
        } else {
          this.localDate.markAsUntouched();
        }
      }
    } else {
      this.localDate.setValue(null);
    }
  }

  private onChangeInputMaxDate() {
    if (this._isMaxDate) {
      if (this._inputMaxDate) {
        if (typeof this._inputMaxDate === 'string') {
          const maxDate = this.validatingDate(this._inputMaxDate, true);
          const formatt = this.dateWithTimeZone(maxDate.split('-')[0], (+maxDate.split('-')[1]) - 1, maxDate.split('-')[2], 0, 0, 0);
          this.maxDate.setValue(formatt);
        } else {
          this.maxDate.setValue(this.dateTimeService.toUTC(this._inputMaxDate));
        }
      } else {
        this.maxDate.setValue(this.currentDate);
      }
    } else {
      this.maxDate.setValue(null);
    }
  }

  private onChangeInputMinDate() {
    if (this._isMinDate) {
      if (this._inputMinDate) {
        if (typeof this._inputMinDate === 'string') {
          const minDate = this.validatingDate(this._inputMinDate, true);
          const formatt = this.dateWithTimeZone(minDate.split('-')[0], (+minDate.split('-')[1]) - 1, minDate.split('-')[2], 0, 0, 0);
          this.minDate.setValue(formatt);
        } else {
          this.minDate.setValue(this.dateTimeService.toUTC(this._inputMinDate));
        }
      } else {
        this.minDate.setValue(this.currentDate);
      }
    } else {
      this.minDate.setValue(null);
    }
  }

  public onDateChange(date: any): void {
    if (this.localDate.status === 'VALID' && this.localDate.value) {
      const adjustedDate = new Date(Date.UTC(moment(this.localDate.value).year(), moment(this.localDate.value).month(), moment(this.localDate.value).date()));
      this.utcDate = this.dateTimeService.toUTC(adjustedDate);
      // this.selectedDate.emit(`${this.utcDate}T00:00:00`);
      this.selectedDate.emit(this.utcDate);
    } else {
      this.toastr.error('Please enter valid date in MM/DD/YYYY format');
      this.utcDate = null;
      this.selectedDate.emit(null);
    }
    this.displayLabel = this.utcDate;
  }

  private validatingDate(value: Date | string, istimeZone: boolean): string {
    const dateSplitter = istimeZone ? new Date(value).toLocaleString('en-US', { timeZone: 'UTC' }).split(',')[0] : new Date(value).toLocaleString('en-US').split(',')[0];
    if (dateSplitter === 'Invalid Date') {
      return null;
    } else {
      // We then parse  the year-month-day into parts
      const month = dateSplitter.split('/')[0].padStart(2, '0');
      const day = dateSplitter.split('/')[1].padStart(2, '0');
      const year = parseInt(dateSplitter.split('/')[2]);
      return `${year}-${month}-${day}`;
    }

  }

  dateWithTimeZone = (year, month, day, hour, minute, second) => {
    let date = new Date(Date.UTC(year, month, day, hour, minute, second));
    let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
    let tzDate = new Date(date.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
    let offset = utcDate.getTime() - tzDate.getTime();
    date.setTime(date.getTime() + offset);
    return date;
  };

}
