import { FirmModel } from 'countable@model';
import { BillingModel } from '../billing/billing.model';
import { ProductModel } from '../billing/product.model';
import { EngagementInterface } from '../eng/engagement.interface';
import { FirmStorageUsageModel } from '../firm/firm-storage-usage.model';
import { FirmTeamCountModel } from '../firm/firm-team-count.model';
import { IntegrationInterface } from '../integration/integration.interface';
import { EngCountModel } from '../eng/eng-count.model';
import { ChangePlanResModel } from '../billing/change-plan-res.model';
import { PaymentMethodResModel } from '../billing/payment-method-res.model';
import { FirmDowngradeModel } from '../firm/firm-downgrades';
import { FlexUsageModel } from '../billing/flex-usage.model';
import { UserTimeZoneModel } from '../user-timezone.model';

export class ApplicationModel {
  engagements = new Map<number, EngagementInterface>();
  integrations = new Map<number, IntegrationInterface>();
  integrationsFullyLoaded: boolean = false;
  storageUsage: FirmStorageUsageModel = null;
  checkConnectionUpdatedAt: string = null;
  firm: FirmModel = null;
  firms: FirmModel[] = null;
  hasMultipleFirms: boolean = false;
  firmTeamCount: FirmTeamCountModel = null;
  EngCount: EngCountModel = null;
  billingProducts: Map<string, ProductModel> = null;
  billingFeatures: Map<number, string> = null;
  billing: BillingModel = null;
  flexPlan: ProductModel = null;
  pausePlan: ProductModel = null;
  trialPeriod: ProductModel = null;
  plan: ProductModel = null;
  ChangePlanRes: ChangePlanResModel = null;
  paymentMethodRes: PaymentMethodResModel[] = null;
  firmDowngrade: FirmDowngradeModel = null;
  flexUsage: FlexUsageModel = null;
  timeZoneList: UserTimeZoneModel = null;
}
