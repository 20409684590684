import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AiDownloadModel, AiFirmInfo, AiFirmModel, AiInfoModel, ChatInputModel, ChatResponseModel, ChatsModel, CommonAIResModel, GenericResModel, SaveChatMsgModel, SavedChatHistoryModel, SaveExcelModel } from 'countable@model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ContextResModel } from '../model/ai/context.model';
import { IntegrationSourceInterface } from '../model/eng/integration-source.interface';
import { CountableTimeZonePipe } from 'countable@helpers';

@Injectable({
  providedIn: 'root'
})

export class AiService {

  public attachedFiles: BehaviorSubject<Map<string, File>> = new BehaviorSubject<Map<string, File>>(null);
  public currentUUID: string;
  public cachedPromptResponse: Map<string, GenericResModel<CommonAIResModel>> = new Map<string, GenericResModel<CommonAIResModel>>([]);
  public cachedChatResponse: Map<string, ChatResponseModel> = new Map<string, ChatResponseModel>([]);
  public isMinimized: boolean = false;
  public progressType: 'PROMPT' | 'FILE_PROMPT' | 'FREE' | 'NONE' = 'NONE';

  constructor(private httpClient: HttpClient, private countableTimeZonePipe: CountableTimeZonePipe) {}

  public fetchGptResponse(data: ChatInputModel): Observable<ChatResponseModel> {
    const uuid = this.generateUuid();
    this.currentUUID = uuid;
    this.progressType = 'FREE';
    data?.history?.forEach(h => delete h?.isShowSpinner);
    const url = environment.ai + 'chatbot/';
    return this.httpClient.post<ChatResponseModel>(url, data).pipe(
      tap(res => this.cacheChatResponse(res, uuid))
    );
  }

  public fetchIntegrationByEngagement(engagementId: number): Observable<IntegrationSourceInterface> {
    return this.httpClient.post<IntegrationSourceInterface>(environment.apiV1 + '/integration/get/engagement', engagementId, {responseType: 'json'});
  }

  public fetchPromptReport(data: FormData): Observable<GenericResModel<CommonAIResModel>> {
    const uuid = this.generateUuid();
    this.currentUUID = uuid;
    this.progressType = 'PROMPT';
    const url = environment.ai + 'ai-prompt';
    return this.httpClient.post<GenericResModel<CommonAIResModel>>(url, data).pipe(
      tap(res => this.cachePromptResponse(res, uuid))
    );
  }

  public fetchPromptReportForAFile(data: FormData): Observable<GenericResModel<CommonAIResModel>> {
    const uuid = this.generateUuid();
    this.currentUUID = uuid;
    this.progressType = 'FILE_PROMPT';
    const url = environment.ai + 'ai-prompt-with-file';
    return this.httpClient.post<GenericResModel<CommonAIResModel>>(url, data).pipe(
      tap(res => this.cachePromptResponse(res, uuid))
    );
  }

  public fetchAiFirmSettings(): Observable<GenericResModel<AiFirmModel>> {
    const url = environment.apiV2 + '/firm/luca';
    return this.httpClient.get<GenericResModel<AiFirmModel>>(url);
  }

  public updateAiFirmStatus(data: AiFirmModel): Observable<GenericResModel<AiFirmModel>> {
    const url = environment.apiV1 + '/luca/firm-settings';
    return this.httpClient.post<GenericResModel<AiFirmModel>>(url, data);
  }

  public fetchAIInformation(): Observable<GenericResModel<AiFirmInfo>> {
    const url = environment.apiV1 + '/luca/setup-count';
    return this.httpClient.get<GenericResModel<AiFirmInfo>>(url);
  }

  public fetchAiUserStatus(): Observable<GenericResModel<AiInfoModel>> {
    const url = environment.apiV1 + '/luca/status';
    return this.httpClient.get<GenericResModel<AiInfoModel>>(url);
  }

  public updateAiUserStatus(): Observable<GenericResModel<boolean>> {
    const url = environment.apiV1 + '/luca/status';
    return this.httpClient.post<GenericResModel<boolean>>(url, {});
  }

  public updateDeactivation(param: string = 'deactivation_notification'): Observable<GenericResModel<boolean>> {
    const url = environment.apiV1 + `/luca/status?action=${param}`;
    return this.httpClient.put<GenericResModel<boolean>>(url, {});
  }

  public fetchChatHistory(engagementId: number = 0): Observable<GenericResModel<ChatsModel[]>> {
    const url: string = environment.apiV1 + `/luca/chats?engagementId=${engagementId}`;
    return this.httpClient.get<GenericResModel<ChatsModel[]>>(url);
  }

  public createOrUpdateChat(chat: ChatsModel): Observable<GenericResModel<ChatsModel>> {
    const url: string = environment.apiV1 + '/luca/chats';
    return this.httpClient.post<GenericResModel<ChatsModel>>(url, chat);
  }

  public downloadAiResponse(data: AiDownloadModel) {
    const url = environment.apiV1 + '/luca/download-report';
    return this.httpClient.post(url, data, {responseType: 'blob'});
  }

  public saveFeedback(data: FormData): Observable<GenericResModel<boolean>> {
    const url = environment.apiV1 + '/luca/save-feedback';
    return this.httpClient.post<GenericResModel<boolean>>(url, data);
  }

  public deleteChat(chat: ChatsModel): Observable<GenericResModel<boolean>> {
    const url = environment.apiV1 + `/luca/delete/chat`;
    return this.httpClient.post<GenericResModel<boolean>>(url, chat);
  }

  public downloadSystemFile(data: AiDownloadModel) {
    const url = environment.apiV1 + '/luca/download-file';
    return this.httpClient.post(url, data, {responseType: 'blob'});
  }

  public saveUserSelectedInputs(data: SaveChatMsgModel): Observable<GenericResModel<SavedChatHistoryModel>> {
    const url = environment.apiV1 + '/luca/chat-messages';
    return this.httpClient.put<GenericResModel<SavedChatHistoryModel>>(url, data);
  }

  public saveToEngagement(data: AiDownloadModel): Observable<GenericResModel<any>> {
    const url = environment.apiV1 + '/luca/save-eng';
    return this.httpClient.post<GenericResModel<any>>(url, data);
  }

  public convertToExcel(data: SaveExcelModel): Observable<GenericResModel<CommonAIResModel>> {
    const url = environment.ai + 'convert_excel/';
    return this.httpClient.post<GenericResModel<CommonAIResModel>>(url, data);
  }

  public getFormattedCreatedAt(createdDate: any, title: string): string {
    const now = new Date();
    const createdAtDate = new Date(createdDate);
    if (this.isCheckSameDay(now, createdAtDate)) {
      return `${title} (Today, ${this.countableTimeZonePipe.transform(createdDate, 'HH:mm a',null)})`;
    }
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (this.isCheckSameDay(yesterday, createdAtDate)) {
      return `${title} (Yesterday, ${this.countableTimeZonePipe.transform(createdDate, 'HH:mm a',null)})`;
    }

    // For older dates
    return `${title} (${this.countableTimeZonePipe.transform(createdDate, 'dd MMM yyyy, hh:mm a',null)})`;
  }

  public isCheckSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  public getContextDetails(engagementId: number, sourceId: number, accountYear: string): Observable<GenericResModel<ContextResModel>> {
    const url: string = environment.apiV1 + `/luca/context/tb?engagementId=${engagementId}&sourceId=${sourceId}&accountYear=${accountYear}`;
    return this.httpClient.get<GenericResModel<ContextResModel>>(url);
  }

  private cachePromptResponse(response: GenericResModel<CommonAIResModel>, uuid: string) {
    this.cachedChatResponse.clear();
    this.cachedPromptResponse.clear();
    if (uuid === this.currentUUID && this.isMinimized) {
      this.cachedPromptResponse.clear();
      this.cachedPromptResponse.set(uuid, response);
    }
  }

  private cacheChatResponse(response: ChatResponseModel, uuid: string) {
    this.cachedChatResponse.clear();
    this.cachedPromptResponse.clear();
    if (uuid === this.currentUUID && this.isMinimized) {
      this.cachedChatResponse.clear();
      this.cachedChatResponse.set(uuid, response);
    }
  }

  public generateUuid(): string {
    this.resetValues();
    const timestamp = Date.now();
    const randomSegment = Math.floor(Math.random() * 1000000);
    return `${timestamp}-${randomSegment}`;
  }

  public resetValues() {
    this.currentUUID = null;
    this.cachedChatResponse.clear();
    this.cachedPromptResponse.clear();
    this.isMinimized = false;
  }
}
