import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ChecklistActionModel, DeleteChecklistModel, GenericResModel, SectionModel, TbInfoModel } from 'countable@model';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { ADDITIONAL_INFO_MAP } from '../helpers/engagement.constants';
import { DialogBoxText, RoutePath } from '../helpers/route-path.constant';
import { FolderTypes, PLANNING_MAP, SubModuleUniqueId } from '../helpers/stored-procedures.constant';
import { DialogInputModel } from '../model/dialog.model';
import { EngSaveModel } from '../model/eng/eng-save.model';
import { EngagementInterface } from '../model/eng/engagement.interface';
import { AssignTeamModel, clientEngagementsListModel, EnagegementNavigationStartModel, EngagementFilteredListModel, EngagementIntegrationInfo } from '../model/eng/engagements-list.model';
import { IntegrationSourceInterface } from '../model/eng/integration-source.interface';
import { FromIssuesModel } from '../model/eng/review-engagement.model';
import { AuthService } from './auth.service';
import { BillingService } from './billing.service';
import { EngApiService } from './eng-api.service';
import { IntegrationService } from './integration.service';
import { StorageService } from './storage.service';
import { CountableTimeZonePipe } from 'countable@helpers';

@Injectable({
  providedIn: 'root'
})
export class EngService {

  // frequently if the data is same
  public engNavigationStarted$: BehaviorSubject<EnagegementNavigationStartModel> = new BehaviorSubject<EnagegementNavigationStartModel>(null);

  //to store details of engs-list from any API call to be used in diffrent places helps in not making API calls
  public sixMonthEngNavigationStarted$: BehaviorSubject<EnagegementNavigationStartModel> = new BehaviorSubject<EnagegementNavigationStartModel>(null);
  public navigateToChecklist$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public navigateToReviewEngagement$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public routePath: typeof RoutePath = RoutePath;
  public subModuleUUID: typeof SubModuleUniqueId = SubModuleUniqueId;
  public engTbYearsInfo: BehaviorSubject<Map<number, TbInfoModel>> = new BehaviorSubject<Map<number, TbInfoModel>>(null);
  public updateShortCut$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public matEsFolders$: BehaviorSubject<typeof PLANNING_MAP> = new BehaviorSubject<typeof PLANNING_MAP>(null);
  public refreshengdetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private refinedClientEngsList;
  private refinedFirmEngsList;
  public refreshAiEngagements$: Subject<boolean> = new Subject<boolean>();
  public refreshEngDocuments$: Subject<boolean> = new Subject<boolean>();
  public refreshSpecificEng$: Subject<number> = new Subject<number>();
  public multiMapDisable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private engApi: EngApiService, private integrationService: IntegrationService,
              private datePipe: DatePipe, public authService: AuthService, private billingService: BillingService, private countableTimeZonePipe:CountableTimeZonePipe) {}

  public deleteIntegrationSource(id: number) {
    StorageService.applicationModel.engagements.get(id).integrationSource = null;
  }

  public async getEngagement(id: number): Promise<EngagementInterface> {
    let engagement: EngagementInterface = StorageService.applicationModel.engagements.get(id);
    if (!engagement) {
      engagement = new EngagementInterface();
    }
    if (!engagement.integrationSource || (engagement?.integrationSource?.isConnected === false)) {
      await this.integrationService.getAccountingIntegrationByEngagement(id).then(result => {
        engagement.integrationSource = result;
        StorageService.applicationModel.engagements.set(id, engagement);
      });
    }
    return StorageService.applicationModel.engagements.get(id);
  }

  public getClientObj() {
    return JSON.parse(localStorage.getItem('clientListObj'));
  }

  public saveEngagement(data: EngSaveModel): Observable<GenericResModel<string>> {
    return this.engApi.saveEngagement(data).pipe(
      map(res => {
        if (res.data && res.status === 200) { this.billingService.refreshBilling().subscribe(); }
        return res;
      }));
  }

  //changing the single Engagement Object Raw Response(JSON) to used across many components
  public getRefinedAllEngagementsList(rawEngagementsList, routeUrl) {
    if (routeUrl === '/dashboard/members/client-engagements') {
      this.refinedClientEngsList = {
        'clientEngagementsList': this.refineClientEngagementsObjects(rawEngagementsList),
        'rawResponse': rawEngagementsList
      };
      this.refinedFirmEngsList = null;
      return this.refinedClientEngsList;
    } else {
      this.refinedFirmEngsList = {
        'firmEngagementsList': this.refineFirmEngagementObjects(rawEngagementsList),
        'rawResponse': rawEngagementsList
      };
      this.refinedClientEngsList = null;
      return this.refinedFirmEngsList;
    }
  }

  //changing the Engagement Object Raw Response(JSON) based ON URL(all-client-list)
  public refineFirmEngagementObjects(engagementsListResponse) {
    const engListFilteredResponse = engagementsListResponse.map(x => this.updateFirmEngagement(x));
    return engListFilteredResponse;
  }

  public updateFirmEngagement(x) {
    let preparerName, firstReviewerName, secondReviewerName;
    let auditorsList, adminName, payloadArray = [];
    if (x.auditor_list) {
      payloadArray = JSON.parse(x.auditor_list);
      auditorsList = JSON.parse(x.auditor_list);
    }
    [preparerName] = payloadArray.filter(s => s.roleid === 1).map(y => <AssignTeamModel>{
      fullName: y.fullname,
      clientAuditorId: y.clientfirmauditroleid
    }).sort();
    [firstReviewerName] = payloadArray.filter(s => s.roleid === 2).map(y => <AssignTeamModel>{
      fullName: y.fullname,
      clientAuditorId: y.clientfirmauditroleid
    }).sort();
    [secondReviewerName] = payloadArray.filter(s => s.roleid === 3).map(y => <AssignTeamModel>{
      fullName: y.fullname,
      clientAuditorId: y.clientfirmauditroleid
    }).sort();
    [adminName] = payloadArray.filter(s => s.auditroleid >= 3).map(y => y.fullname) || '';
    x.finanicalyearenddate = x.finanicalyearenddate ? x.finanicalyearenddate : '';
    x.engcreateddate = x.engcreateddate ? x.engcreateddate + ' UTC' : '';
    return {
      'engagementName': x.engagementname.charAt(0).toUpperCase() + x.engagementname.slice(1),
      'engagementname': x.engagementname.charAt(0).toUpperCase() + x.engagementname.slice(1),
      'clientName': x.businessname ? x.businessname : '',
      'clientfirmid': x.clientfirmid,
      'clientCity': x.clientCity,
      'clientState': x.clientState,
      'engagementsid': x.engagementsid, //added
      'pengagementtypeid': x.engagementtypeid, //change from  engagementid to engagementtypeid
      'engagementtype': x.engagementtype ? x.engagementtype : '',
      'yearEnd': this.countableTimeZonePipe.transform(x.finanicalyearenddate,'MMM DD, yyyy', 'UTC'),
      'prvYear': x.prvYear,
      'assignedTo': 'View Assignees',
      'status': x.statusname ? x.statusname : '',
      'dateCreated': this.datePipe.transform(x.engcreateddate, 'MMM dd, yyyy hh:mm a'),
      'Action': '',
      'additionalinfo': x.additionalinfo,
      'auditor_list': x.auditor_list,
      'compilationtype': x.compilationtype,
      'incorporationdate': x.incorporationdate,
      'subenties': x.subenties,
      'contactperson': x.contactperson,
      'useraccountid': x.useraccountid,
      'statusid': x.statusid,
      'sourcelinkid': this.getSourceLinkId(x.sourceLink),
      'tbloadstatusid': x.tbloadstatusid,
      'preparer': !preparerName ? '' : preparerName.fullName,
      'firstReviewerName': !firstReviewerName ? '' : firstReviewerName.fullName,
      'secondReviewerName': !secondReviewerName ? '' : secondReviewerName.fullName,
      'Admin': !adminName ? '' : adminName,
      'isowned': x.isowned,
      'auditorsData': auditorsList,
      'emailaddress': x.emailaddress ? x.emailaddress : '',
      'cellphonenumber': x.cellphonenumber ? x.cellphonenumber : '',
      'typeOfEntityId': x.typeOfEntityId,
      'typeOfEntityName': x.typeOfEntityName,
      'duration': x.duration,
      'clientDataSourceId': x.clientDataSourceId, //to check whether Eng. connected to Online Software or not
      'isCompleteEngagement': x.isCompleteEngagement,// To check engagement status from Completion section
      'rftemplateid': x.rftemplateid, // rftemplateId to  check trail-balance data available for current year or not
      'accountingTypeid': x.accountingTypeid,
      'accountingTypename': x.accountingTypename,
      'subentityName': x.subentityName,
      'typeOfSubentityId': x.typeOfSubentityId,
      'typeOfSubentityUID': x.typeOfSubentityUID,
      'compilationid': x.compilationid,
      'additionalDisclosureId': x.additionalDisclosureId,
      'clientStatusId': x.clientStatusId
    };
  }

  ////changing the Engagement Object Raw Response(JSON) based ON URL(single-client-list)
  public refineClientEngagementsObjects(engagementsListResponse): clientEngagementsListModel {
    const clientObj = this.getClientObj();
    const clientEngagementsList = engagementsListResponse.filter(e => e.clientfirmid === clientObj.clientfirmId);
    const engFilteredList = new EngagementFilteredListModel();
    engFilteredList.totalengagement = clientEngagementsList ? clientEngagementsList.length : 0;
    engFilteredList.current = clientEngagementsList.filter(e => (e.statusid === 7 || e.statusid === 9)) ? clientEngagementsList.filter(e => (e.statusid === 7 || e.statusid === 9)).length : 0;
    engFilteredList.completed = clientEngagementsList.filter(e => e.statusid === 8) ? clientEngagementsList.filter(e => e.statusid === 8).length : 0;
    engFilteredList.archived = clientEngagementsList.filter(e => e.statusid === 19) ? clientEngagementsList.filter(e => e.statusid === 19).length : 0;
    engFilteredList.totalclient = clientEngagementsList ? clientEngagementsList.length : 0;
    const engagementFilteredResponse = this.refineFirmEngagementObjects(engagementsListResponse);
    const clientEngsObj = new clientEngagementsListModel();
    clientEngsObj.rawResponse = [...engagementsListResponse];
    clientEngsObj.engagementsAvailable = engagementFilteredResponse && engagementFilteredResponse.length > 0;
    clientEngsObj.engagementsList = [...engagementFilteredResponse];
    clientEngsObj.filteredListObj = engFilteredList;
    clientEngsObj.sortedEngCount = engagementFilteredResponse ? engagementFilteredResponse.length : 0;
    return clientEngsObj;
  }

  //to set details of integration to display LOGO,ConnectedClientName(orgName)
  public getEngIntegrationInfo(tenantInfo: IntegrationSourceInterface) {
    const integrationDetails: EngagementIntegrationInfo = new EngagementIntegrationInfo();
    if (tenantInfo.integrationId === 1 && tenantInfo.isActive === true) {
      integrationDetails.integrationName = 'QuickBooks';
      integrationDetails.logoPath = '/assets/images/quickbook/active-qb.png';
      integrationDetails.integrationId = 1;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = true;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.classId = 1;
      integrationDetails.status = this.getEngagementObj().status;
    } else if (tenantInfo.integrationId === 1 && tenantInfo.isActive === false) {
      integrationDetails.integrationName = 'QuickBooks';
      integrationDetails.logoPath = '/assets/images/quickbook/error-qb.png';
      integrationDetails.integrationId = 1;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = false;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.classId = 2;
      integrationDetails.status = this.getEngagementObj().status;
    } else if (tenantInfo.integrationId === 2 && tenantInfo.isActive === true) {
      integrationDetails.integrationName = 'Xero';
      integrationDetails.logoPath = '/assets/images/xero-img.png';
      integrationDetails.integrationId = 2;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = true;
      integrationDetails.classId = 3;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.status = this.getEngagementObj().status;
    } else if (tenantInfo.integrationId === 2 && tenantInfo.isActive === false) {
      integrationDetails.integrationName = 'Xero';
      integrationDetails.logoPath = '/assets/images/xero/error-xero1.png';
      integrationDetails.integrationId = 2;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = false;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.classId = 4;
      integrationDetails.status = this.getEngagementObj().status;
    } else if (tenantInfo.integrationId === 4 && tenantInfo.isActive === true) {
      integrationDetails.integrationName = 'Sage';
      integrationDetails.logoPath = 'assets/images/sage/Sage-logo.png';
      integrationDetails.integrationId = 4;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = true;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.classId = 5;
      integrationDetails.status = this.getEngagementObj().status;
    } else if (tenantInfo.integrationId === 4 && tenantInfo.isActive === false) {
      integrationDetails.integrationName = 'Sage';
      integrationDetails.logoPath = 'assets/images/sage/error-sage.png';
      integrationDetails.integrationId = 4;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = false;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.classId = 6;
      integrationDetails.status = this.getEngagementObj().status;
    }
    return integrationDetails;
  }

  getS3Folder(bucketName: string) {
    return (bucketName === 'client-docs-uat' || bucketName === 'client-docs-qa-01' || bucketName === 'client-docs-dev-01');
  }

  public getEngagementObj() {
    return JSON.parse(localStorage.getItem('selectedEngagementObjCopy'));
  }

  public getSelectedEngagementObj() {
    return JSON.parse(localStorage.getItem('selectedEngagementObj'));
  }

  public getEngagementsId(): string {
    return localStorage.getItem('engagementID');
  }

  public getShortCutMenuDetails(): Object {
    return localStorage.getItem('shortCutMenuDetails');
  }

  public getEngagementType(): 'Compilation' | 'Review' {
    return this.getSelectedEngagementObj().pengagementtypeid === 1 ? 'Compilation' : 'Review';
  }

  public isEngagementAssignedToUser() {
    const assignedMembersList = this.getAssignedMembersToEngagement();
    const userLoginId: number = this.authService.getUserDetail().useracctid;
    let isMemberAssignedToEngagement: boolean = false;
    (assignedMembersList && assignedMembersList.length > 0) && assignedMembersList.forEach(m => {
      if (userLoginId === m.useraccountid) {
        isMemberAssignedToEngagement = true;
      }
    });
    localStorage.setItem('engAssigned', JSON.stringify(isMemberAssignedToEngagement));
  }

  public setFormData(parentFolderData, subFolderData, originFolderUUID: string,
                     from: FolderTypes): FromIssuesModel {
    let issuesObj: FromIssuesModel = new FromIssuesModel();
    switch (from) {
      case 'Client-Onboarding':
        issuesObj = this.setIssuesObj(parentFolderData, subFolderData, originFolderUUID);
        break;
      case 'Planning':
        issuesObj = this.setIssuesObj(parentFolderData, subFolderData, originFolderUUID);
        break;
      case 'Documents':
        issuesObj = this.setDocsObj(parentFolderData, subFolderData, originFolderUUID);
        break;
      case 'Trail-Balance':
        issuesObj = this.setTBObj();
        break;
      case 'Financial-Statements':
        issuesObj = this.setFSObj(parentFolderData, subFolderData, originFolderUUID);
        break;
      case 'Completion-Signoffs':
        issuesObj = this.setCSObj(subFolderData, originFolderUUID);
        break;
    }
    return issuesObj;
  }

  /**
   * @param subModuleId
   * Unique id for an Engagement Left Side Folder
   * The Configuration Data For Checklist Delete DialogBox using Common DialogBox
   * along with Configuration for DialogBox Using DialogService
   */
  public getChecklistDeleteData(subModuleId: number): MatDialogConfig<any> {
    const Obj: DeleteChecklistModel = new DeleteChecklistModel();
    Obj.engagementsid = +this.getEngagementsId();
    Obj.loginid = this.authService.getUserDetail().useracctid;
    Obj.submoduleid = subModuleId;
    const data: DialogInputModel = new DialogInputModel();
    data.data = {...Obj};
    data.dialogType = 'delete_checklist';
    data.dialogHeader = DialogBoxText.DELETE_CHECKLIST_HEADER;
    const config: MatDialogConfig<any> = new MatDialogConfig<any>();
    config.data = {...data};
    config.disableClose = false;
    config.id = 'delete_checklist';
    return config;
  }

  /**
   * @param Obj
   * Incoming Data Emitted from ADD Checklist Button
   * The Data For Checklist Adding From Templates DialogBox using Common DialogBox
   * along with Configuration for DialogBox Using DialogService
   */

  public getChecklistAddData(Obj: ChecklistActionModel): MatDialogConfig<any> {
    const data: DialogInputModel = new DialogInputModel();
    data.dialogType = 'save_new_checklist';
    data.dialogHeader = DialogBoxText.ADD_CHECKLIST;
    data.data = {...Obj};
    const config: MatDialogConfig<any> = new MatDialogConfig<any>();
    config.disableClose = true;
    config.autoFocus = true;
    config.height = '100%';
    config.position = {'top': '53px', right: '0'};
    config.data = {...data};
    config.disableClose = false;
    config.id = 'save_new_checklist';
    return config;
  }

  setFolderConfigData(Obj: any, originId: any) {
    const data: DialogInputModel = new DialogInputModel();
    data.dialogType = 'move_leftsidedoc';
    data.dialogHeader = 'Move';
    data.data = [...Obj];
    data.originId = originId;
    const config: MatDialogConfig<any> = new MatDialogConfig<any>();
    config.data = {...data};
    config.disableClose = false;
    config.id = 'move_leftsidedoc';
    return config;
  }

  /**
   * @param onBoardingResponse
   * Response from getClientOnboarding SP
   * Extracting Checklist SubModuleID
   * @param subModuleUuid
   */
  setReviewChecklist(onBoardingResponse: any, subModuleUuid: string) {
    if (!onBoardingResponse) {
      return;
    }
    const folderList = JSON.parse(onBoardingResponse);
    const planningFolder = folderList.filter(folder => folder.subModuleUUID === SubModuleUniqueId.PLANNING_REVIEW);
    if (!planningFolder) {
      return;
    }
    switch (subModuleUuid) {
      case SubModuleUniqueId.PLANNING_REVIEW:
        const checkListFolder = folderList.filter(folder => folder.subModuleUUID === SubModuleUniqueId.CLIENT_ONBOARDING);
        const checkListData = checkListFolder[0] ? checkListFolder[0].child.filter(section => section.subModuleUUID !== SubModuleUniqueId.ENGAGEMENT_LETTER) : [];
        const checkListId = checkListData[0] ? checkListData[0]['id'] : 0;
        this.navigateToChecklist$.next(checkListId);
        break;

    }

  }

  setLetterSignedOffStatus(onBoardingResponse: any) {
    if (!onBoardingResponse) {
      return;
    }
    const folderList = JSON.parse(onBoardingResponse);
    const letterFolder = folderList.filter(folder => folder.subModuleUUID === SubModuleUniqueId.CLIENT_ONBOARDING);
    if (!letterFolder) {
      return;
    }
    const engLetterObj = letterFolder[0] ? letterFolder[0].child.filter(section => section.subModuleUUID === SubModuleUniqueId.ENGAGEMENT_LETTER) : [];
    if (!engLetterObj) {
      return;
    }
    const letterDocObj = engLetterObj.children ? engLetterObj.children[0] : {};
  }

  public getEngTypeId() {
    return (this.getSelectedEngagementObj() && this.getSelectedEngagementObj().pengagementtypeid === 2) ||
      (this.getEngagementObj() && this.getEngagementObj().engagementtypeid === 2) ||
      ((this.getEngagementObj() && this.getEngagementObj().pengagementtypeid === 2)) ||
      (this.getSelectedEngagementObj() && this.getSelectedEngagementObj().engagementtypeid === 2);
  }

  public setMaterialityEsObj(response: any) {
    if (!response) {
      return;
    }
    const onBoardingResponse = JSON.parse(JSON.stringify(response));
    const folderList = JSON.parse(onBoardingResponse);
    const planningFolder = folderList.filter(folder => folder.subModuleUUID === SubModuleUniqueId.PLANNING_REVIEW);
    if (!planningFolder) {
      return;
    }
    const matEsFolders = planningFolder[0].child.filter(folder => folder.subModuleUUID === SubModuleUniqueId.MATERIALITY ||
      folder.subModuleUUID === SubModuleUniqueId.ENGAGEMENT_SCOPE);
    const mapFolders = matEsFolders.map(f => <SectionModel>{
      is_copied_to_engagement: f.is_copied_to_engagement,
      name: f.name,
      is_master: f.is_master,
      JsonFileName: f.JsonFileName,
      JsonFilePath: f.JsonFilePath,
      id: f.id,
      subModuleUUID: f.subModuleUUID
    });
    if (mapFolders && mapFolders.length !== 2) {
      return;
    }
    const matEsMap = new Map([...PLANNING_MAP]);
    matEsMap.set('Materiality', mapFolders.filter(folder => folder.subModuleUUID === SubModuleUniqueId.MATERIALITY)[0]);
    matEsMap.set('Engagement Scope', mapFolders.filter(folder => folder.subModuleUUID === SubModuleUniqueId.ENGAGEMENT_SCOPE)[0]);
    const currentMap = this.matEsFolders$.getValue();
    this.matEsFolders$.next(matEsMap);
  }
  // workbook current and Previous years
  getPreviousAndCurrentDate(yearEndDetails: any, selectedYearEnd: any) {
    let obj = {
      currentYear: '',
      previousYear: '',
      selectedYearTitle: ''
    };
    if (yearEndDetails?.length && selectedYearEnd) {
      const filterDate: any = structuredClone(yearEndDetails.filter((e: any) => e.acctyear === selectedYearEnd));
      if (filterDate && filterDate.length) {
        obj.currentYear = filterDate[0].acctdate;
        obj.selectedYearTitle = this.datePipe.transform(filterDate[0].acctdate, 'MMM d, yyyy');
      }
      const prevIndex = yearEndDetails.findIndex((e: any) => e.acctyear === selectedYearEnd);
      if (!(yearEndDetails[yearEndDetails.length - 1].acctyear === selectedYearEnd) && yearEndDetails[prevIndex + 1]) {
        obj.previousYear = yearEndDetails[prevIndex + 1].acctdate;
      }
    }

    return obj;
  }

  isTaxTemplate(engagementDetails: any): boolean {
    if (engagementDetails?.pengagementtypeid == 1 || engagementDetails?.pengagementtypeid == 2) {
      return false; // Not a Tax Engagement
    } else if (engagementDetails?.engagementtypeid == 1 || engagementDetails?.engagementtypeid == 2) {
      return false; // Not a Tax Engagement
    } else {
      return true; // Tax Engagement
    }
  }

  getEngagementDetails(engagementId?: number, type: 'shortCode' | 'taxName' = 'shortCode'): string {
    const engagementDetailsMap: { [key: number]: { shortCode: string, taxName: string } } = {
      1: {shortCode: 'COM-', taxName: 'COM'},
      2: {shortCode: 'REV-', taxName: 'REV'},
      34: {shortCode: 'T2-', taxName: 'T2'},
      35: {shortCode: '1120-', taxName: '1120'},
      36: {shortCode: '1120S-', taxName: '1120S'},
      37: {shortCode: '1065-', taxName: '1065'}
    };

    const engagementDetail = engagementDetailsMap[engagementId] || {shortCode: '', taxName: ''};

    return type === 'shortCode' ? engagementDetail.shortCode : engagementDetail.taxName;
  }

  getHypenabbr(yearEndDateAbbr): string {
    return yearEndDateAbbr === '' ? '' : '-';
  }

  getAdditionalEngagementInfo(selectedEngagementType): {
    info1: string,
    info2: string,
    info3: string,
    heading: string
  } {
    return ADDITIONAL_INFO_MAP[selectedEngagementType?.id] || {info1: '', info2: '', info3: '', heading: ''};
  }

  private async getIntegrationDetail(engagementId): Promise<IntegrationSourceInterface> {
    let engagement: EngagementInterface = StorageService.applicationModel.engagements.get(engagementId);
    if (!engagement || !engagement.integrationSource) {
      await this.engApi.fetchIntegrationSource(engagementId).then(result => {
        if (!engagement) {
          engagement = new EngagementInterface();
        }
        engagement.integrationSource = result;
        StorageService.applicationModel.engagements.set(engagementId, engagement);
      });
    }
    return StorageService.applicationModel.engagements.get(engagementId).integrationSource;
  }

  private getAssignedMembersToEngagement(): Array<any> {
    const selectedEngagementObj = this.getSelectedEngagementObj();
    let assignedMembersList = [];
    if (selectedEngagementObj.auditor_list) {
      assignedMembersList = JSON.parse(selectedEngagementObj.auditor_list);
    } else if (selectedEngagementObj.auditorrole) {
      assignedMembersList = JSON.parse(selectedEngagementObj.auditorrole);
    } else if (selectedEngagementObj.Assignedteam.firmteam) {
      assignedMembersList = selectedEngagementObj.Assignedteam.firmteam;
    }
    return assignedMembersList;
  }

  private setIssuesObj(parentFolderData, subFolderData, originFolderUUID): FromIssuesModel {
    const issuesObj: FromIssuesModel = new FromIssuesModel();
    issuesObj.folderType = ((originFolderUUID.subModuleUUID === this.subModuleUUID.CLIENT_ONBOARDING) || (originFolderUUID.subModuleUUID === this.subModuleUUID.PLANNING_REVIEW)) ? 'parent-folder' : 'child-folder';
    issuesObj.selectType = 1;
    issuesObj.submoduleid = ((originFolderUUID === this.subModuleUUID.CLIENT_ONBOARDING) || (originFolderUUID === this.subModuleUUID.PLANNING_REVIEW)) ? parentFolderData.id : subFolderData.id;
    issuesObj.from = (parentFolderData.subModuleUUID === this.subModuleUUID.CLIENT_ONBOARDING) ? 'Client-Onboarding' : 'Planning';
    issuesObj.leftSideMenu = parentFolderData.id;
    issuesObj.originFolderUUID = originFolderUUID;
    issuesObj.parentFolderTitleName = parentFolderData.name;
    issuesObj.subFolderTitleName = subFolderData ? subFolderData.name : '';
    return issuesObj;
  }

  private setDocsObj(parentFolderData, subFolderData, originFolderUUID): FromIssuesModel {
    const issuesObj: FromIssuesModel = new FromIssuesModel();
    issuesObj.submoduleid = originFolderUUID === this.subModuleUUID.DOCUMENTS ? parentFolderData.submoduleid
      : subFolderData.submoduleid;
    issuesObj.leftSideMenu = originFolderUUID === this.subModuleUUID.DOCUMENTS ? parentFolderData.submoduleid
      : subFolderData.parentsubmoduleid;
    issuesObj.parentFolderTitleName = parentFolderData.submodulename;
    issuesObj.subFolderTitleName = subFolderData ? subFolderData.submodulename : '';
    issuesObj.selectType = 1;
    issuesObj.originFolderUUID = originFolderUUID === this.subModuleUUID.DOCUMENTS ? this.subModuleUUID.DOCUMENTS :
      subFolderData.subModuleUUID;
    issuesObj.from = 'Documents';
    issuesObj.folderType = originFolderUUID === this.subModuleUUID.DOCUMENTS ? 'parent-folder' : 'child-folder';
    return issuesObj;
  }

  private setTBObj(): FromIssuesModel {
    const issuesObj: FromIssuesModel = new FromIssuesModel();
    issuesObj.submoduleid = 12;
    issuesObj.leftSideMenu = 12;
    issuesObj.parentFolderTitleName = 'Trial Balance & Adj.Entries';
    issuesObj.subFolderTitleName = null;
    issuesObj.selectType = 1;
    issuesObj.from = 'Trail-Balance';
    issuesObj.folderType = 'parent-folder';
    return issuesObj;
  }

  private setFSObj(parentFolderData, subFolderData, originFolderUUID): FromIssuesModel {
    const issuesObj: FromIssuesModel = new FromIssuesModel();
    issuesObj.folderType = originFolderUUID === this.subModuleUUID.FINANCIAL_STATEMENTS ? 'parent-folder' : 'child-folder';
    issuesObj.selectType = 1;
    issuesObj.submoduleid = originFolderUUID === this.subModuleUUID.FINANCIAL_STATEMENTS ? parentFolderData.submoduleid : subFolderData.submoduleid;
    issuesObj.from = 'Financial-Statements';
    issuesObj.leftSideMenu = parentFolderData.submoduleid;
    issuesObj.originFolderUUID = this.subModuleUUID.FINANCIAL_STATEMENTS;
    issuesObj.parentFolderTitleName = parentFolderData.submodulename;
    issuesObj.subFolderTitleName = subFolderData ? subFolderData.submodulename : '';
    return issuesObj;
  }

  private setCSObj(subFolderData, originFolderUUID: string): FromIssuesModel {
    const issuesObj: FromIssuesModel = new FromIssuesModel();
    issuesObj.folderType = originFolderUUID === this.subModuleUUID.COMPLETION_SIGNOFFS ? 'parent-folder' : 'child-folder';
    issuesObj.from = originFolderUUID === this.subModuleUUID.COMPLETION_SIGNOFFS ? 'Completion-Signoffs' : subFolderData.submodulename;
    issuesObj.leftSideMenu = +(this.subModuleUUID.COMPLETION_SIGNOFFS);
    issuesObj.originFolderUUID = originFolderUUID === this.subModuleUUID.COMPLETION_SIGNOFFS ? this.subModuleUUID.COMPLETION_SIGNOFFS : subFolderData.SMUFID;
    issuesObj.parentFolderTitleName = 'Completion & Signoffs';
    issuesObj.subFolderTitleName = subFolderData ? subFolderData.submodulename : '';
    issuesObj.selectType = 1;
    issuesObj.submoduleid = originFolderUUID === this.subModuleUUID.COMPLETION_SIGNOFFS ? +(this.subModuleUUID.COMPLETION_SIGNOFFS) : subFolderData.submoduleid;
    return issuesObj;
  }

  private getSourceLinkId(sourceLink) {
    sourceLink = sourceLink?.toLowerCase();
    if (sourceLink === 'quickBooks') { return 1; } else if (sourceLink === 'xero') { return 2; } else if (sourceLink === 'freshbook') { return 3; } else if (sourceLink === 'sage') { return 4; } else {
      return 0;
    }
  }

}

