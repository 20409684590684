import { DatePipe, formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CountableTimeZonePipe } from 'countable@helpers';


@Pipe({
    name: "timeago",
    pure: true
})
export class TimeAgoPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string, private countableTimeZonePipe: CountableTimeZonePipe) { }
    transform(value: any, args?: any): any {
        if (value) {
            const currentUtcDate = new Date(Date.now());
            const givenDate = new Date(value+' UTC');
            const secondsDiff = Math.abs((currentUtcDate.getTime() - givenDate.getTime()) / 1000);
            if (secondsDiff < 29 && args == "TB") // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            if (secondsDiff < 86400 && args == "CLIENT")
                return this.countableTimeZonePipe.transform(value, 'HH:mm a',null);
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(secondsDiff / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }
}

