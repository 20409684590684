import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { DocumentPreviewTypes } from 'countable@helpers';
import { RoundingModel } from 'countable@model';
import { ApiService } from '../../../../services/api.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { idleTime, modules } from 'src/app/services/app-config';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { SharedService } from '../../../shared/shared.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatMenuTrigger } from '@angular/material/menu';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { FirmModel } from 'src/app/model/firm/firm.model';
import { FirmService } from 'src/app/services/firm.service';
import { BillingService } from 'src/app/services/billing.service';
import { EngService } from 'src/app/services/eng.service';

@Component({
  selector: 'app-retained-earnings',
  templateUrl: './retained-earnings.component.html',
  styleUrls: ['./retained-earnings.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,

})
export class RetainedEarningsComponent implements OnInit, OnDestroy {

  @ViewChild('sortingMenu') sortingMenu: MatMenuTrigger;
  @ViewChild('saveReLeadsheetDialog') saveLeadsheetalertforRe: TemplateRef<any>;
  @ViewChild('saveReMapNoDialog') saveMapNoalertforRe: TemplateRef<any>;
  @ViewChild('multipleMoveMenu') multipleMoveMenu: MatMenuTrigger;
  @ViewChild('notesFSLinkingDialog') notesFSLinkingDialog: TemplateRef<any>;
  notesSRc: any;
  userLoginId: any;
  accYearEnd: number;
  firmUserAcctId: any;
  disablegreenbutton: boolean = true;
  isSelectAll: boolean = false;
  notes: any[];
  getlinkarray: any = [];

  private subject: Subject<string> = new Subject();
  selectedEngagementObj: any;
  acctYear: any;
  retainedEarningsData: any;
  isincomeStatementExpanded: boolean = false;
  headerNotesColumn1 = '';
  headerNotesColumn2: any;
  headerNotesColumn3: any;
  engagementData: any;
  submoduleId: number;
  incomeHeaderInfo: any;
  isSecondColumnClicked: boolean = false;
  isThirdColumnClicked: boolean = false;
  userData: any;
  clientName: any;
  yearEndDetails: any = [];
  selectedYearEnd: any;
  engagementYearEnd: any;
  isFooterNoteEdited = false;
  oldFooterNote: string;
  headerSubmoduleNameSub: Subscription;
  workBookSubModuleId: any;
  currentYear: any;
  previousYear: any;
  searchText: any;
  restrictedColumn: boolean;
  footerNote: string;
  isPrevyear: boolean;
  editingTitle = false;
  isindividualRowSaveforRE: boolean = true;
  TbLeadSheetId: any;
  tbMapSheetId: any;
  @ViewChild('focusInputField') focusInputField: ElementRef;
  totalValues: any;
  retainedEarningsExpanded: boolean = true;
  isSearchExist = true;
  confirmLeadsheetSub: Subscription;
  unAssignMapSub: Subscription;
  leadSheetId: any;
  isDisableMoveButton: any;
  mapSheetId: any;
  leadSheetName: string;
  selectedLSTrailBLrow: any = [];
  dialogRef: any;
  mapNo: any;
  autoSortApplied = false;
  addPixel = 218;
  subGroupSubModuleId: any;
  engagementId: any;
  sortData = [];
  disableSort = true;
  sortGroup: string = "AUTO";
  selectedYearTitle: string;
  sortArray: any[] = [{ "name": "Automatic", "value": "AUTO" }, { "name": "Manual", "value": "MAN" }];
  checkedManualData: any = [];
  sortCheckboxLength: number;
  isDisableUpArrow: boolean = true;
  isDisableDownArrow: boolean = true;
  isASC: boolean = false;
  sortSub: Subscription;
  selectedTrailBLrow: any = [];
  connectedTo = [];
  subChildArray: any;
  leadsheetdisable: boolean = false;
  selectedLSmenuPositions = [];
  activeMapMove = false;
  activeLsMove = false;
  lsMenuPopupHeight = 145;
  shortCutMenuDetails: any;
  accessPermissions: any;
  searchKeyword: string;
  FSItemstolink: any = [];
  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {
    if (ev.key == 'RetainedEarningStatement') {
      let isyesno = parseInt(localStorage.getItem('isyesno'))
      if (isyesno == 0) {
        this.router.navigate(['/trial-balance-working-sheet/income-statement']);
        localStorage.setItem('workBookSubmoduleId', '436');
      }
    }

    if (ev.key == 'refreshWorkBookISRE') {
      this.refreshRetainedEarnings();
    }
  }
  trackobj: { engagementsId: any; submoduleId: any; submoduleUuid: any; };
  userActivity;
  userInactiveSub: Subscription;
  userInactive: Subject<any> = new Subject();
  @HostListener('window:mousemove', ["$event"])
  @HostListener('window:resize', ["$event"])
  @HostListener('window:keydown', ["$event"])
  refreshUserState() {
    clearTimeout(this.userActivity);
    if (localStorage.getItem("timerStatus") == "Idle")
      this.trackerService.startTimer("Active");
    this.setTimeout();
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    localStorage.setItem("isOpenWB", "false");
  }
  crossCheckValues = {
    currentYear: 0,
    previousYear: 0
  }
  billing: BillingModel;
  firm: FirmModel;
  private allSubscriptions: Subscription = new Subscription();
  headerFooterDataSubject: Subject<any> = new Subject();


  public readonly roundingData$: BehaviorSubject<RoundingModel> = new BehaviorSubject<RoundingModel>(null);

  constructor(public router: Router, private apiService: ApiService, private datePipe: DatePipe, private sharedservice: SharedService,
    private toastr: ToastrService, private spinner: NgxSpinnerService, private trackerService: TimeTrackerService, private firmService: FirmService,
    private billingService: BillingService, public dialog: MatDialog, public workbookservice: EngService) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.trackerService.startTimer("Idle");
    });
    this.allSubscriptions.add(
      this.firmService.subject.subscribe(firm => {
        if (firm) {
          this.firm = firm;
        }
      })
    )

    this.allSubscriptions.add(
      this.billingService.subject.subscribe(billing => {
        if (billing) {
          this.billing = billing;
        }
      })
    )
  }

  ngOnInit() {
    localStorage.removeItem("refreshFinancialStatements-BSH");
    localStorage.removeItem("refreshFinancialStatements-BS");
    localStorage.removeItem("refreshFinancialStatements-ISH");
    localStorage.removeItem("refreshFinancialStatements-IS");
    localStorage.removeItem("refreshFinancialStatements-LSG");
    localStorage.removeItem("refreshWorkBookISRE");

    this.isPrevyear = false;
    this.restrictedColumn = false //need to check with corporation and update the value
    this.userData = JSON.parse(localStorage.getItem('userDetails'));
    this.submoduleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
    this.engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"));
    this.workBookSubModuleId = localStorage.getItem('workBookSubmoduleId');
    this.selectedEngagementObj = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    let selectedObj = JSON.parse(localStorage.getItem("selectedEngagementObjCopy"));
    this.shortCutMenuDetails = JSON.parse(localStorage.getItem('shortCutMenuDetails'));
    this.firmUserAcctId = this.sharedservice.getUserData("field", "firmUserAcctId");
    this.engagementId = this.engagementData.engagementsid;
    this.engagementYearEnd = localStorage.getItem("endYear");
    this.userLoginId = this.sharedservice.getUserData("field", "useracctid");
    this.clientName = selectedObj.clientName;
    let abc = this.datePipe.transform(this.selectedEngagementObj.yearEnd, 'y');
    this.acctYear = parseInt(abc);
    this.trackobj = {
      "engagementsId": this.engagementData.engagementsid,
      "submoduleId": this.submoduleId,
      "submoduleUuid": localStorage.getItem("WUUID"),
    }
    localStorage.setItem("timeTrackerObj", JSON.stringify(this.trackobj));
    this.trackerService.startTimer("Active");
    this.getTBLSHeaderNotes(false);
    this.getYearEndDetails();
    // call Retained data
    this.getRetainedEarningsData(this.searchText);
    this.confirmLeadsheetSub = this.sharedservice.isMovingLeadsheet.subscribe(res => {
      if (res.subscribeFrom === "IS") {

        if (res.moveType === "SINGLE") {
          if (res.moveFrom === "LS") {
            this.confirmSaveLeadsheet(res.parent, res.child, res.yearType);
          }
          else {
            this.confirmSaveMapNo(res.parent, res.child, res.yearType);
          }
        }
        else {
          this.isDisableMoveButton = res.isDisableMoveButton;
          if (res.moveFrom === "LS") {
            this.leadSheetDetail(res.child);
          }
          else {
            this.mapSheetData(res.child);
          }
        }
      }
    })
    this.unAssignMapSub = this.sharedservice.unAssignMapNumber.subscribe(res => {
      if (res) {
        this.saveUnMappingItems(res);
      }
    });
    this.sortSub = this.sharedservice.sortingPosition.subscribe(res => {
      if (res === true) {
        this.addPixel = 218;
      }
      else {
        this.addPixel = 0;
      }
    });
    this.subject.pipe(
      // if character length greater then 2
      filter(res => res.length > 2 || res.length < 3)
      // Time in milliseconds between key events
      , debounceTime(1000)
      // If previous query is diffent from current
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.getRetainedEarningsData(text);
    });
    this.headerSubmoduleNameSub = this.sharedservice.leftSideMenuNameChange.subscribe(response => {
      if (response) {
        this.getTBLSHeaderNotes();
      }
    });
    this.getShowHideUserPermissions(this.userData.useracctid);
    this.getFSNotes();
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), idleTime);
  }
  getTBLSHeaderNotes(isSetKey?: boolean) {
    let obj = {
      "engagementsid": this.engagementData.engagementsid,
      "submoduleid": this.submoduleId
    }

    let finalObj = JSON.stringify(obj);
    let data = {
      "inputParameters": finalObj,
      "procedureName": "getwbheadernote"
    }

    this.spinner.show()
    this.apiService.getData(data).subscribe(response => {
      this.spinner.hide()
      if (response) {
        this.incomeHeaderInfo = JSON.parse(response);
        this.headerNotesColumn1 = this.incomeHeaderInfo[0].header[0].changednameline1;
        this.headerNotesColumn2 = this.incomeHeaderInfo[0].header[0].changednameline2;
        this.headerNotesColumn3 = this.incomeHeaderInfo[0].header[0].changednameline3;
        this.footerNote = this.incomeHeaderInfo[1].note ? this.incomeHeaderInfo[1].note[0].note : "";
        this.sharedservice.retainedSarnings.next(this.incomeHeaderInfo);
        if (isSetKey == undefined) {
          localStorage.setItem('refreshFinancialStatements-REH', "success");
        }
      }
    }, error => {
      this.toastr.error(error.error)
    });

  }
  getYearEndDetails() {
    this.apiService.getEngagementYearDetails(this.engagementData.engagementsid).subscribe(response => {
      if (response) {
        let yearEndDetails = response;
        this.yearEndDetails = yearEndDetails;
        this.selectedYearEnd = this.yearEndDetails[0].acctyear;
        this.engagementYearEnd = this.yearEndDetails[0].acctdate;
      }
    }, error => {
      this.toastr.error("Error in getting Year End Details");
    })
  }
  saveFooterNote() {
    this.footerNote = this.footerNote.trim();
    if (!this.footerNote) {
      this.toastr.warning("Please enter the notes");
      return;
    }
    if (this.footerNote.length > 250) {
      this.toastr.warning("Text length is limited to 250 characters");
      return;
    }
    let obj = {
      "submoduleid": this.submoduleId,
      "engagementsid": this.engagementData.engagementsid,
      "note": this.footerNote,
      "isdelete": 0,
      "loginid": this.userData['useracctid']
    }

    let saveobj = JSON.stringify(obj);
    let data = {
      "procedureName": "savewbnote",
      "inputParameters": saveobj
    }
    localStorage.removeItem('isfooterUpdated');
    this.spinner.show();
    this.apiService.saveData(data).subscribe(response => {
      this.spinner.hide();
      localStorage.setItem('isfooterUpdated', this.footerNote);
      this.headerFooterDataSubject.next(this.footerNote);
      this.toastr.success("Footer Details Updated Successfully");
      this.isFooterNoteEdited = false;
      // this.getTBLSHeaderNotes();
      // this.refreshRetainedEarnings();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  editFooterNotes() {
    this.focusInput();
    this.oldFooterNote = this.footerNote;
    this.isFooterNoteEdited = true;
  }
  cancelEditFooterNote() {
    this.footerNote = this.oldFooterNote
    this.isFooterNoteEdited = false;
  }
  focusInput() {
    setTimeout(() => {
      this.focusInputField.nativeElement.focus();
    }, 0);
  }

  saveHeaderNote() {
    let obj = {
      "submoduleid": this.workBookSubModuleId,
      "engagementsid": this.engagementData.engagementsid,
      "changednameline2": this.headerNotesColumn2,
      "changednameline3": this.headerNotesColumn3,
      "isdelete": 0,
      "loginid": this.userData['useracctid']
    }

    let saveobj = JSON.stringify(obj)
    let data = {
      "procedureName": "savewbheadernamechange",
      "inputParameters": saveobj
    }

    this.spinner.show();
    this.apiService.saveData(data).subscribe(response => {
      this.spinner.hide();
      this.toastr.success("Header Details updated Successfully");
      this.isSecondColumnClicked = false;
      this.isThirdColumnClicked = false;
      this.getTBLSHeaderNotes();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  getFSNotes() {
    this.isSelectAll = false;
    let jsonFileName = this.engagementId + '_notes.json';
    const uploadObj = {
      "module": modules[16],
      "firmId": this.firmUserAcctId,
      "clientId": this.engagementData.clientfirmid,
      "engagementId": this.engagementData.engagementsid,
      "status": 0,
      "folderName": jsonFileName,
      "appSyncId": this.userLoginId,
      "extension": 'comp',
    }
    this.spinner.show();
    this.apiService.getJsonData(uploadObj).subscribe((response: any) => {
      if (response) {
        this.notes = !response ? [] : JSON.parse(response)
        console.log(this.notes)
        this.notes.forEach(ele => {
          ele['isExpand'] = false;
          ele['is_checked'] = false;
          if (ele.notes) {
            ele.notes.forEach(element => {
              element['is_checked'] = false;
            });
          }
        })

        this.spinner.hide();
      }
    }, (error) => {
      this.spinner.hide();
      this.notes = [];
    });
  }
  // getIncomesheet_Notes_Link_Obj() {
  //   for (let i = 0; i < this.retainedEarningsData.length; i++) {
  //     if(this.retainedEarningsData[i].children && this.retainedEarningsData[i].children.length){
  //     for (let j = 0; j < this.retainedEarningsData[i].children.length; j++) {
  //       if(this.retainedEarningsData[i].children[j].children && this.retainedEarningsData[i].children[j].children.length){
  //       for (let k = 0; k < this.retainedEarningsData[i].children[j].children.length; k++) {
  //         var indx = this.retainedEarningsData[i].children[j].children.findIndex(x => x.chldid == this.notesSRc.chldid)
  //         if (indx > -1) {
  //           this.retainedEarningsData[i].children[j].children[indx].notes=this.retainedEarningsData[i].children[j].children[indx].notes?this.retainedEarningsData[i].children[j].children[indx].notes:[];
  //           return this.retainedEarningsData[i].children[j].children[indx];
  //         }
  //       }
  //     }
  //     }
  //   }
  //   }
  // }
  // get_NotesSrc_Obj(event) {
  //   for (let i = 0; i < this.retainedEarningsData.length; i++) {
  //     for (let j = 0; j < this.retainedEarningsData[i].children.length; j++) {
  //       if (this.retainedEarningsData[i].children[j].children.find(x => x.chldid == event.chldid)) {
  //         return this.retainedEarningsData[i].children[j].children.find(x => x.chldid == event.chldid)
  //       }
  //     }
  //   }
  // }

  getIncomesheet_Notes_Link_Obj() {
    for (let i = 0; i < this.retainedEarningsData.length; i++) {
      if (this.retainedEarningsData && this.retainedEarningsData.length) {
        var indx = this.retainedEarningsData.findIndex(x => x.chldid == this.notesSRc.chldid)
        if (indx > -1) {
          this.retainedEarningsData.notes = this.retainedEarningsData[indx].notes ? this.retainedEarningsData[indx].notes : [];
          return this.retainedEarningsData[indx];
        }
      }
    }
  }

  get_NotesSrc_Obj(event) {
    for (let i = 0; i < this.retainedEarningsData.length; i++) {
      if (this.retainedEarningsData.find(x => x.chldid == event.chldid)) {
        return this.retainedEarningsData.find(x => x.chldid == event.chldid)
      }
    }
  }

  getCheckedNotes() {
    var selected_Records = [];
    this.notes.forEach(elem => {
      if (elem.is_checked) {
        if (elem.notes) {
          elem.notes.forEach(element => {
            selected_Records.push(element)
          });
        }
        else {
          selected_Records.push(elem)
        }
      }
      else if (elem.notes) {
        elem.notes.forEach(element => {
          if (element.is_checked) {
            selected_Records.push(element)
          }
        });
      }
    })
    return selected_Records
  }
  // saveLinkNotes() {
  //   var selected_Records = [];
  //   selected_Records = this.getCheckedNotes();
  //   var previously_Selected = this.getIncomesheet_Notes_Link_Obj();
  //   var linksavearray = [];
  //   selected_Records.forEach(sobj => {
  //     var indx = -1;
  //     if (previously_Selected.notes) {
  //       indx = previously_Selected.notes.findIndex(x => x.engNoteId == sobj.engNoteId)
  //     }
  //     var obj = {
  //       "engagementsId": this.engagementId,
  //       "finSubgroupChildId": this.notesSRc.finsubgroupchildId,
  //       "submoduleId": this.submoduleId,
  //       "noteName": sobj.name,
  //       "noteId": sobj.id.toString(),
  //       "engNoteId": sobj.engNoteId.toString(),
  //       "isChanged": 0,
  //       "isDelete": 0,
  //       "loginId": this.userLoginId,
  //       "acctYear": this.accYearEnd,
  //       "workbookNotesId": indx > -1 ? previously_Selected.notes[indx].workbooknotesid : 0
  //     }
  //     linksavearray.push(obj)
  //   })
  //   var payload = {
  //     "save": linksavearray.length > 0 ? linksavearray : [{
  //       "engagementsId": this.engagementId,
  //       "finSubgroupChildId": this.notesSRc.finsubgroupchildId,
  //       "submoduleId": this.submoduleId,
  //       "noteName": '',
  //       "noteId": '',
  //       "engNoteId": '0',
  //       "isChanged": 0,
  //       "isDelete": 0,
  //       "loginId": this.userLoginId,
  //       "acctYear": this.accYearEnd,
  //       "workbookNotesId": 0
  //     }]
  //   }
  //   this.apiService.saveNotesBalanceSheet(payload).subscribe(resp => {
  //     if (resp && resp == 'Success') {
  //       this.toastr.success("Successfully Completed")
  //       this.refreshPreview();
  //       this.getRetainedEarningsData("");
  //     }
  //   })
  // }

  saveLinkNotes() {
    this.spinner.show();
    var selected_Records = [];
    selected_Records = this.getCheckedNotes();
    var previously_Selected = this.getIncomesheet_Notes_Link_Obj();
    var linksavearray = [];
    selected_Records.forEach(sobj => {
      var indx = -1;
      if (previously_Selected.notes) {
        indx = previously_Selected.notes.findIndex(x => x.engNoteId == sobj.engNoteId)
      }
      var obj = {
        "engagementsId": this.engagementId,
        "finSubgroupChildId": this.notesSRc.chldid ? this.notesSRc.chldid : '',
        "finSubgroupId": '',
        "submoduleId": this.submoduleId,
        "noteName": sobj.name,
        "noteId": sobj.id.toString(),
        "engNoteId": sobj.engNoteId.toString(),
        "isChanged": 0,
        "isDelete": 0,
        "loginId": this.userLoginId,
        "acctYear": this.acctYear,
        "workbookNotesId": indx > -1 ? previously_Selected.notes[indx].workbooknotesid : 0
      }
      linksavearray.push(obj)
    })
    var payload = {
      "save": linksavearray.length > 0 ? linksavearray : [{
        "engagementsId": this.engagementId,
        "finSubgroupChildId": this.notesSRc.chldid ? this.notesSRc.chldid : '',
        "finSubgroupId": '',
        "submoduleId": this.submoduleId,
        "noteName": '',
        "noteId": '',
        "engNoteId": '0',
        "isChanged": 0,
        "isDelete": 0,
        "loginId": this.userLoginId,
        "acctYear": this.acctYear,
        "workbookNotesId": 0,
      }]
    }
    this.apiService.saveNotesBalanceSheet(payload).subscribe(resp => {
      if (resp && resp == 'Success') {
        this.toastr.success("Successfully Completed")
        this.refreshPreview();
        this.getRetainedEarningsData("");
      }
    })
    this.spinner.hide();
  }

  refreshPreview() {
    this.getTBLSHeaderNotes();
    this.sharedservice.refreshWorkbookmenu.next(true);
    let obj = {
      "currentYear": parseInt(this.selectedYearEnd ? this.selectedYearEnd : this.datePipe.transform(this.engagementData.yearEnd, 'y')),
      "isRefresh": true
    }
    localStorage.setItem('refreshFinancialStatements-IS', "success");
    localStorage.setItem('refreshTrialbalance', "success");
    // this.sharedservice.TSselectedYear.next(obj);
    const message = DocumentPreviewTypes.RETAINED_EARNINGS;
    console.log("Post-Message-Started");
    window.opener.postMessage(message, '*');
    const refreshMap = this.sharedservice.setRefreshOfStatement(DocumentPreviewTypes.INCOME_STATEMENT);
    this.sharedservice.updateFinStatement$.next(refreshMap);
  }
  navigateToNotesToFs(id) {
    this.router.navigate(['/trial-balance-working-sheet/fs-notes'], { queryParams: { NoteId: id } });
  }
  searchNotes() {
    if (this.searchKeyword != "" || this.searchKeyword.trim() != "") {
      for (let k = 0; k < this.notes.length; k++) {
        if (this.notes[k].notes && this.notes[k].notes.filter(x => x.name.toLowerCase().includes(this.searchKeyword))) {
          var indx = this.notes[k].notes.findIndex(y => y.name.toLowerCase().includes(this.searchKeyword));
          this.notes[k].isExpand = indx > -1 ? true : false
          return
        }
      }
    } else {
      this.notes.forEach(exp => {
        exp.isExpand = false;
      })
    }

  }
  resetNotesIs_Checked() {
    this.notes.forEach(ele => {
      ele.is_checked = false;
      if (ele.notes) {
        ele.notes.forEach(element => {
          element.is_checked = false;
        });
      }
    })
  }
  selectAll(event) {
    for (let index = 0; index < this.notes.length; index++) {
      this.notes[index].is_checked = event.checked
      if (this.notes[index].notes) {
        this.notes[index].notes.forEach(element => {
          element.is_checked = event.checked
        });
      }
    }

  }
  is_recordChecked(i, checkedType, j?) {
    if (checkedType == "category") {
      this.notes[i].is_checked = !this.notes[i].is_checked;
      if (this.notes[i].notes) {
        this.notes[i].notes.forEach(element => {
          element.is_checked = this.notes[i].is_checked == true ? true : false
        });
      }
      this.isSelectAll = this.notes.findIndex(x => x.is_checked == false) < 0 ? true : false
    } else if (checkedType == "subcategory") {
      this.notes[i].notes[j].is_checked = !this.notes[i].notes[j].is_checked;
      this.notes[i].is_checked = this.notes[i].notes.findIndex(x => x.is_checked == false) < 0 ? true : false
      this.isSelectAll = this.notes.findIndex(x => x.is_checked == false) < 0 ? true : false
    }
  }

  changednotesLinks(event?) {
    this.resetNotesIs_Checked()
    this.notesSRc = event ? this.get_NotesSrc_Obj(event) : this.notesSRc
    var balance_obj = event ? event : this.getIncomesheet_Notes_Link_Obj();
    this.disablegreenbutton = (balance_obj.notes && balance_obj.notes.length > 0) ? true : false
    if (balance_obj.notes) {
      for (let k = 0; k < this.notes.length; k++) {
        for (let i = 0; i < balance_obj.notes.length; i++) {
          if (this.notes[k].engNoteId == balance_obj.notes[i].engNoteId) {
            this.is_recordChecked(k, 'category');
          } else if (this.notes[k].notes) {
            for (let j = 0; j < this.notes[k].notes.length; j++) {
              if (this.notes[k].notes[j].engNoteId == balance_obj.notes[i].engNoteId) {
                this.is_recordChecked(k, 'subcategory', j);
              }
            }
          }
        }
      }
    }
  }
  notesFSLinkingSelectionDialogFunc(child2?) {
    // this.resetNotesIs_Checked();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '100%';
    dialogConfig.position = { 'top': '53px', right: '0' };
    this.changednotesLinks(child2);
    const dialogRef = this.dialog.open(this.notesFSLinkingDialog, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }


  //Get Retained data
  getRetainedEarningsData(searchTerm) {
    this.FSItemstolink = [];
    let search = searchTerm ? searchTerm : 'null';
    let obj = {
      "engagementsid": this.selectedEngagementObj.engagementsid,
      "acctyear": parseInt(this.selectedYearEnd ? this.selectedYearEnd : this.acctYear),
      "submoduleid": this.submoduleId,
      "searchtext": search
    }
    //"engagementsid":47
    let stringObj = JSON.stringify(obj);
    let data = {
      "procedureName": "getWFSincomestatement",
      "inputParameters": stringObj
    }
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {

      this.spinner.hide();
      if (response) {
        let data = JSON.parse(response);
        //debugger;
        this.totalValues = data;
        this.restrictedColumn = data.isnotesshow == 1 ? true : false;
        this.isSearchExist = data.searchexist == 1 ? true : false;
        this.retainedEarningsData = data.groups;
        if (data?.groups && data.groups.length) {
          let crossCheck = [];
          data.groups.forEach(element => {
            if ((element?.name && element.name.toLowerCase().includes('beginning of year')) || (element?.totalname && element.totalname.toLowerCase().includes('beginning of year'))) {
              crossCheck.push(element);
            }
            if ((element?.name && element.name.toLowerCase().includes('end of year')) || (element?.totalname && element.totalname.toLowerCase().includes('end of year'))) {
              crossCheck.push(element);
            }
          });
          if (crossCheck.length && crossCheck.length === 1) {
            crossCheck.push(data.groups.slice(-1)[0]);
          }

          console.log(crossCheck)
          crossCheck.forEach((p, i) => {
            if (i === 0) {
              if (p?.Currgrouptotal && p.Currgrouptotal === '-') {
                this.crossCheckValues.currentYear = 0;
              } else {
                this.crossCheckValues.currentYear = p?.Currgrouptotal ? (p.Currgrouptotal.includes('(') ? Number(-p.Currgrouptotal.replace(/[(,)]/g, "")) : Number(+p.Currgrouptotal.replace(/[(,)]/g, ""))) : 0;
              }

            } else if (i === 1) {
              if (p?.Prevgrouptotal && p.Prevgrouptotal === '-') {
                this.crossCheckValues.previousYear = 0;
              } else {
                this.crossCheckValues.previousYear = p?.Prevgrouptotal ? (p.Prevgrouptotal.includes('(') ? Number(-p.Prevgrouptotal.replace(/[(,)]/g, "")) : Number(+p.Prevgrouptotal.replace(/[(,)]/g, ""))) : 0;
              }

            }
          });
        }
        this.retainedEarningsData.map((obj) => {
          if (obj.hasOwnProperty('trialbal')) {
            obj.ShowAdd = true
            this.FSItemstolink.push(obj);
          }
        });
        var prev = this.retainedEarningsData[0]['Prevgrouptotal'];
        this.getPreviousAndCurrentDate();
        if (prev || prev == 0) {
          this.isPrevyear = true
        }
        else {
          this.isPrevyear = false
        }
        this.defaultDragging();
        this.showRoundingDifference(data);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error)
    });
  }

  showRoundingDifference(incomeStatement: any) {
    const roundingViewData = this.sharedservice.showRoundingDifference(incomeStatement)
    this.roundingData$.next(roundingViewData);
  }

  defaultDragging() {
    this.connectedTo = [];
    this.retainedEarningsData.forEach(p => {
      if (p.trialbal != undefined) {
        this.connectedTo.push(p.id + '');
      }
      else {
        p.isexpand = true;
      }
      if (p.children == undefined) {
        p.isexpand = true;
      }
      if (p.id === 12 && p.children) {
        p.children.forEach(s => {
          if (s.children) {
            s.children.forEach(sc => {
              this.connectedTo.push(sc.id + '');
            });
          }
        });
      }
    });
  }
  handleSearch(searchTerm: string) {
    this.subject.next(searchTerm);
  }

  refreshRetainedEarnings() {
    const filterDate = structuredClone(this.yearEndDetails.filter(e => e.acctyear === this.selectedYearEnd));
    if (filterDate && filterDate.length) {
      localStorage.setItem('workbookChangeYearEnd', new Date(filterDate[0].acctdate).toISOString().slice(0, 10));
    }
    this.getPreviousAndCurrentDate();
    localStorage.setItem('isLastYear', (this.yearEndDetails[this.yearEndDetails.length - 1].acctyear === this.selectedYearEnd) ? 'true' : 'false')

    localStorage.setItem('workbookChangeDate', this.selectedYearEnd);
    this.getTBLSHeaderNotes();
    this.sharedservice.refreshWorkbookmenu.next(true);
    this.getRetainedEarningsData(this.searchText);
    localStorage.removeItem('refreshFinancialStatements-RE')
    localStorage.setItem('refreshFinancialStatements-RE', "success");
    localStorage.setItem('refreshTrialbalance', "success");
    this.sharedservice.reloadRetainEarningsPreview.next(this.selectedYearEnd);
    localStorage.removeItem('refreshWorkBookISRE');
  }
  editingTitleCheck(data) {
    if (this.editingTitle === false) {
      this.focusInput();
      data.isEditable = !data.isEditable;
      this.editingTitle = true;
    } else {
      this.toastr.warning("Please save the data");
    }
  }
  closeTitleEdit(data) {
    data.isEditable = !data.isEditable
    this.editingTitle = false;
  }
  UpdateTitles(objchild, type) {
    var obj = {
      "engagementsid": this.engagementData.engagementsid,
      //"acctyear": parseInt(this.datePipe.transform(this.engagementData.yearEnd, 'y')),
      "acctyear": parseInt(this.selectedYearEnd ? this.selectedYearEnd : this.datePipe.transform(this.engagementData.yearEnd, 'y')),
      "fsid": objchild.chldid,
      "fstype": "incomeStatement",
      "currentname": objchild.oldvalue,
      "newname": objchild.name,
      "ploginid": this.userData.useracctid
    }
    this.apiService.saveFsDetails(obj).subscribe(response => {
      if (response) {
        this.editingTitle = false;
        this.toastr.success("Group name updated successfully");
        this.refreshRetainedEarnings();
      }
      else {
        this.toastr.warning("Failed to update group name");
      }
    }, error => {
      this.editingTitle = false;
      this.toastr.error(error.error)
    })

  }
  reParent(index, item) {
    return index; // or item.id
  }
  reChild(index, item) {
    return index; // or item.id
  }
  reSubChild(index, item) {
    return index; // or item.id
  }
  expandAndCollapse(type) {
    let mode = false;
    if (type === 'expand') {
      mode = true;
    } else {
      mode = false;
    }
    this.retainedEarningsExpanded = mode;
    this.retainedEarningsData.forEach(element => {
      element.isexpand = mode;
      if (element.children) {
        element.children.forEach(child => {
          child.isexpand = mode;
          if (child.children) {
            child.children.forEach(subchild => {
              subchild.isexpand = mode;
            })
          }
        })
      }
    })
  }
  activeLSDropdown(obj, type) {
    if (this.isindividualRowSaveforRE) {
      if (type === 'previous') {
        obj.isLeadSheet = true;
        this.TbLeadSheetId = obj.prevleadsheetid;
      } else {
        obj.isLeadSheet = true;
        this.TbLeadSheetId = obj.leadsheetid;
      }
    }
  }
  mappingNumber(obj, type) {
    if (this.isindividualRowSaveforRE) {
      if (type == "previous") {
        obj.isMap = true;
        this.tbMapSheetId = obj.prevmapsheetid;
      } else {
        obj.isMap = true;
        this.tbMapSheetId = obj.mapsheetid;
      }
    }
  }
  confirmSaveLeadsheet(value, LSData, yearType: "PREVIOUS" | "CURRENT") {
    this.leadSheetId = LSData.leadsheetid;
    let trialbalanceId = yearType === "PREVIOUS" ? value.prevtrailbalanceid : value.trailbalanceid;
    let mapsheetId = yearType === "PREVIOUS" ? value.prevmapsheetid : value.mapsheetid;
    this.leadSheetName = LSData.leadsheetcode + "-" + LSData.leadsheetname;
    this.selectedLSTrailBLrow = [];
    var leadSheetObj = {
      "accountname": value.accountname,
      "leadsheet": value.leadsheet,
      "leadsheetid": value.leadsheetid,
      "mapno": value.mapno,
      "mapsheetid": mapsheetId,
      "trailbalanceid": trialbalanceId
    }
    this.selectedLSTrailBLrow.push(leadSheetObj)
    this.dialogRef = this.dialog.open(this.saveLeadsheetalertforRe, { disableClose: true });
  }
  saveLeadSheetdetails() {
    var mapdetails = []
    if (this.selectedLSTrailBLrow) {
      this.selectedLSTrailBLrow.map(x => {
        mapdetails.push({
          "trailbalanceid": x.trailbalanceid,
          "leadsheetid": this.leadSheetId, "mastermapsheetid": x.mapsheetid, "loginid": this.userData['useracctid'],
          "submoduleid": this.submoduleId, "fsid": 0
        })
      })
    }
    var leadSheetObj = {
      "leadsheetchange": mapdetails
    }
    var finalSaveMappingObj = JSON.stringify(leadSheetObj)
    var getLeadSheetGroupObj = {
      "inputParameters": finalSaveMappingObj,
      "procedureName": "savebsisleadsheetgrouping"
    }
    this.spinner.show();
    this.apiService.saveData(getLeadSheetGroupObj).subscribe(response => {
      if (response) {
        this.spinner.hide();
        this.toastr.success("Moved Successfully");
        this.refreshRetainedEarnings();
        // this.isLeadSheet = false;
        this.leadSheetId = ''
        //   this.mapNodialogRef.close(true)
        //this.getRetainedEarningsData(this.searchText);
        //this.clearSelectedData();required
        //this.multipleMoveMenu.closeMenu();required

      }
      else {
        this.spinner.hide();
        this.toastr.error("Failed to move Lead Sheet");
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  mapSheetData(value) {
    this.mapSheetId = value.mastermapsheetid;
  }
  leadSheetDetail(value) {
    this.leadSheetId = value.leadsheetid
  }
  confirmSaveMapNo(value, MapSheet, yearType: "PREVIOUS" | "CURRENT") {
    this.mapNo = MapSheet.mapno;
    this.mapSheetId = MapSheet.mastermapsheetid;
    let trialbalanceId = yearType === "PREVIOUS" ? value.prevtrailbalanceid : value.trailbalanceid;
    let mapsheetId = yearType === "PREVIOUS" ? value.prevmapsheetid : value.mapsheetid;
    this.selectedLSTrailBLrow = [];
    var mapSheetObj = {
      "accountname": value.accountname,
      "leadsheet": value.leadsheet,
      "leadsheetid": value.leadsheetid,
      "mapno": value.mapno,
      "mapsheetid": value.mapsheetid,
      "trailbalanceid": trialbalanceId
    }
    this.selectedLSTrailBLrow.push(mapSheetObj)
    this.dialog.open(this.saveMapNoalertforRe, { disableClose: true });
    // this.dialogRef.afterClosed().subscribe(result => {
    // });
  }
  savemappingdetails() {
    var mapdetails = []
    if (this.selectedLSTrailBLrow) {
      this.selectedLSTrailBLrow.map(x => {
        mapdetails.push({
          "trailbalanceid": x.trailbalanceid,
          "mapsheetid": this.mapSheetId,
          "loginid": this.userData['useracctid'],
          "acctyear": parseInt(this.selectedYearEnd ? this.selectedYearEnd : this.datePipe.transform(this.engagementData.yearEnd, 'y'))
        })
      })
    }
    var MappingObj = {
      "tbmapping": mapdetails
    }
    var finalSaveMappingObj = JSON.stringify(MappingObj)
    var getLeadSheetGroupObj = {
      "inputParameters": finalSaveMappingObj,
      "procedureName": "savenewtrailbalancemapping"
    }
    this.spinner.show();
    this.apiService.saveData(getLeadSheetGroupObj).subscribe(response => {
      if (response) {
        this.spinner.hide();
        this.toastr.success("Moved Successfully");
        this.refreshRetainedEarnings();
        // this.selectedTrailBLrow=true;
        this.selectedLSTrailBLrow = []
        this.mapSheetId = ''
        //this.dialogRef.close(true);
        //this.isMoveforIS = false;
        this.isindividualRowSaveforRE = true
        this.multipleMoveMenu.closeMenu();
        //this.clearSelection();
        this.sharedservice.sendNotifySubject.next({ 'uuid': this.userData.useracctid, 'isfirm': 1 });
      }
      else {
        this.spinner.hide();
        this.toastr.success("Failed to move mapping");
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  saveUnMappingItems(data) {
    let trialbalanceId = data.yearType === "PREVIOUS" ? data.parent.prevtrailbalanceid : data.parent.trailbalanceid;
    let inputObject = [{
      "trailbalanceid": trialbalanceId,
      "loginid": this.userData['useracctid']
    }]
    var saveMapObj = {
      "unmapping": inputObject
    }
    var finalSaveMappingObj = JSON.stringify(saveMapObj)
    var getLeadSheetGroupObj = {
      "inputParameters": finalSaveMappingObj,
      "procedureName": "unmaptrialbalance"
    }
    this.spinner.show();
    this.apiService.saveData(getLeadSheetGroupObj).subscribe(response => {
      if (response == 'true') {
        this.spinner.hide();
        this.refreshRetainedEarnings();
        this.getRetainedEarningsData(this.searchText);
        this.toastr.success("Unmapped successfully");
      }
      else {
        this.toastr.error("Unmapping failed");
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });

  }
  getSubGroupSortingData(data, event) {
    this.autoSortApplied = false;
    const span: HTMLElement = document.querySelector('.sort-position');
    span.style.left = (event.pageX - 55 - this.addPixel) + 'px';
    span.style.top = (event.pageY - 45) + 'px';
    this.subGroupSubModuleId = data.submoduleid;
    const inputObj = {
      "engagementsid": this.engagementId,
      "acctyear": parseInt(this.selectedYearEnd ? this.selectedYearEnd : this.datePipe.transform(this.engagementData.yearEnd, 'y')),
      "finsubgrpid": data.id,
      "submoduleid": this.submoduleId
    }
    const stringifyObj = JSON.stringify(inputObj);
    // const finalInputObj = {
    //   "inputParameters": stringifyObj,
    //   "procedureName": "getwbsortsubgrpnames"
    // }
    this.spinner.show();
    this.apiService.getSubgroupSort(this.userLoginId, inputObj).subscribe(response => {
      if (response) {
        this.spinner.hide();
        let res = JSON.parse(response);
        this.sortingMenu.openMenu();
        this.sortData = res;
        this.disableSort = true;
        this.sortGroup = 'AUTO';
        this.checkedManualData = [];
        this.sortCheckboxLength = 0;
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  disableSortBtn(value) {
    //autoSortApplied
    if (value === 'MAN') {
      if (this.checkedManualData.length > 0) {
        this.disableSort = false;
      } else {
        this.disableSort = true;
      }
    } else if (value === 'AUTO') {
      if (this.autoSortApplied === true) {
        this.disableSort = false;
      } else {
        this.disableSort = true;
      }
    }
  }
  manualSorting(type?) {
    let totalCount = this.sortData.length;
    let checkedIndex = this.sortData.findIndex(e => e.checked);
    if (type === "UP") {
      if (checkedIndex != 0)
        this.move(this.sortData, checkedIndex, checkedIndex - 1);
    }
    else {
      if (checkedIndex != totalCount - 1)
        this.move(this.sortData, checkedIndex, checkedIndex + 1);
    }
    this.disableSort = false;
  }
  move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
  onChangeAddManualData(obj) {
    this.sortCheckboxLength = this.sortData.filter(e => e.checked === true).length;
    if (obj.checked === true) {
      this.checkedManualData.push(obj);
    }
    else {
      this.checkedManualData.map((x, index) => {
        if (x.finsubgroupchildId == obj.finsubgroupchildId) {
          this.checkedManualData.splice(index, 1);
        }
      })
    }
    if (this.checkedManualData.length == 0 || this.checkedManualData.length == this.sortData.length) {
      this.isDisableDownArrow = true;
      this.isDisableUpArrow = true;
      this.disableSort = true;
    }
    else {
      this.isDisableDownArrow = false;
      this.isDisableUpArrow = false;
      this.disableSort = false;
    }
  }
  onCLickSaveSorting() {
    let inputData = [];
    let sortType = this.sortGroup == "AUTO" ? true : false;
    this.sortData.map(e => {

      /*
      let obj = {
        "engagementsid": this.engagementId,
        "submoduleid": e.submoduleid,
        "acctyear": parseInt(this.selectedYearEnd ? this.selectedYearEnd : this.datePipe.transform(this.engagementData.yearEnd, 'y')),
        "isautomatic": sortType,//auto : true; manual: false
        "sortorder": "desc",
        "finsubgroupchildId": e.finsubgroupchildId,
        "bsissortorderid": e.bsissortorderid,
        "bsissortorderitemid": e.bsissortorderitemid,
        "childid": e.childid,
        "loginid": this.userData['useracctid'],
      }


      */
      let obj = {
        "engagementsId": this.engagementId,
        "submoduleId": this.subGroupSubModuleId,
        "acctYear": parseInt(this.selectedYearEnd),
        "isAutomatic": sortType,//auto : true; manual: false
        "sortOrder": "desc",
        "finSubgroupChildId": e.finsubgroupchildId,
        "bsIsSortOrderId": e.bsissortorderid,
        "bsIsSortOrderItemId": e.bsissortorderitemid,
        "childId": e.childid,
        "loginId": this.userLoginId
      }
      inputData.push(obj);
    })
    const inputObj = {
      "sort": inputData
    }
    /*
    const stringifyObj = JSON.stringify(inputObj);
    const finalInputObj = {
      "inputParameters": stringifyObj,
      "procedureName": "savewbsort"
    }
    */
    this.spinner.show();
    this.apiService.saveSubgroupSort(inputObj).subscribe(response => {
      //this.apiService.saveData(finalInputObj).subscribe(response => {
      if (response) {
        this.spinner.hide();
        //this.sortingMenu.closeMenu();
        this.toastr.success("Records sorted successfully");
        this.refreshRetainedEarnings();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  closeSort() {
    this.sortingMenu.closeMenu();
  }
  toggleSort() {
    this.isASC = !this.isASC;
    this.sortData.sort((a, b) => {
      let x = parseInt(a.value.replace(/[()]/g, ''));
      let y = parseInt(b.value.replace(/[()]/g, ''));
      let comparison = 0;
      if (x > y) {
        comparison = 1;
      } else if (x < y) {
        comparison = -1;
      }
      return (this.isASC ? (comparison * -1) : comparison);
    });
    this.disableSort = false;
    this.autoSortApplied = true;
  }
  clearRowSelection() {

  }
  showTotalScore(item) {
    item.isexpand = !item.isexpand;
  }
  drop(event: CdkDragDrop<any[]>, data) {
    let obj = {};
    let dropedleadsheetId = 0;
    dropedleadsheetId = event.container.data[0].leadsheetid;
    let fid: any;
    if (data.isuseradded === 1) {
      let ids = data.chldid.split('.', 2);
      fid = ids[0] + "." + ids[1];
    } else {
      fid = '0';
    }
    if (event.previousContainer !== event.container) {
      const leadsheetId = event.container.data[0].leadsheetid;
      const mapsheetId = event.container.data[0].mapsheetid;
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      let fid: any;
      if (data.isuseradded === 1) {
        let ids = data.chldid.split('.', 2);
        fid = ids[0] + "." + ids[1];
      } else {
        fid = '0';
      }
      obj = {
        trailbalanceid: event.item.data.trailbalanceid,
        leadsheetid: leadsheetId,
        mastermapsheetid: mapsheetId,
        loginid: this.userData['useracctid'],
        fsid: fid,
        submoduleid: this.submoduleId
      }
      this.saveDraggingItem(obj);
    }
  }
  saveDraggingItem(obj) {
    var leadSheetObj = {
      "leadsheetchange": []
    }
    leadSheetObj.leadsheetchange.push(obj);
    var finalSaveMappingObj = JSON.stringify(leadSheetObj)
    var getLeadSheetGroupObj = {
      "inputParameters": finalSaveMappingObj,
      "procedureName": "savebsisleadsheetgrouping"
    }
    this.spinner.show();
    this.apiService.saveData(getLeadSheetGroupObj).subscribe(response => {
      if (response) {
        this.spinner.hide();
        this.toastr.success("Moved Successfully");
        this.refreshRetainedEarnings();
        //this.getRetainedEarningsData(this.searchText);
        this.isindividualRowSaveforRE = true;
      }
      else {
        this.spinner.hide();
        this.toastr.error("Failed to move leadsheet");
      }

    }, error => {
      this.spinner.hide();
      this.getRetainedEarningsData(this.searchText);
      this.toastr.error(error.error);
    });
  }
  mouseEnterHandler(event: MouseEvent, chapterExpansionPanel: MatExpansionPanel) {
    if (event.buttons && !chapterExpansionPanel.expanded) {
      chapterExpansionPanel.open();
    }
  }
  checkChange(checkeditem, completeObj, subGroupId, groupData) {
    if (completeObj.trialbal) {
      var objcount = completeObj.trialbal.length;
      this.subChildArray = completeObj;
      var count = 0;
      completeObj.trialbal.map(b => {
        if (b.checked == true) {
          count = count + 1;
        }
        else {
          count = count - 1;
        }
      });
      if (objcount == count) {
        completeObj.checked = true;
      } else {
        completeObj.checked = false;
      }
    }
    if (checkeditem.checked == true) {
      if (subGroupId != 'otherIncomes') {
        checkeditem.subGroupId = subGroupId;
      } else {
        let ids = completeObj.chldid.split('.');
        checkeditem.subGroupId = ids[1];
      }
      checkeditem.groupId = groupData ? groupData.id : '';
      if (completeObj.id)
        this.selectedLSTrailBLrow.push(checkeditem);
    }
    else {
      this.selectedLSTrailBLrow.map((x, index) => {
        if (x.trailbalanceid == checkeditem.trailbalanceid) {
          this.selectedLSTrailBLrow.splice(index, 1);
        }
      })
    }
    var unmappedcount = 0
    this.selectedLSTrailBLrow.map(d => {
      if (d.mapsheetid == 0) {
        unmappedcount = unmappedcount + 1
      }
    });
    //this.multipleUnmappedMoveMenu.closeMenu();
    // for unmapped selection
    if (unmappedcount > 0) {
      this.leadsheetdisable = true;
      //this.multipleUnmappedMoveMenu.openMenu();
    }
    else if (unmappedcount > 0) {
      this.leadsheetdisable = true;
      //this.multipleUnmappedMoveMenu.closeMenu();
    }
    else {
      this.leadsheetdisable = false;
    }
    this.multipleMoveMenu.closeMenu();
    //for enable map no and leadsheet selection
    if (this.selectedLSTrailBLrow.length > 1) {
      this.multipleMoveMenu.openMenu();
      const menuDiv: HTMLElement = document.querySelector('#LSMAPDialog');
      if (menuDiv)
        menuDiv.style.display = 'none';

    } else {
      const parent: HTMLElement = document.querySelector('.cdk-overlay-connected-position-bounding-box');
      if (parent != null) {
        parent.style['margin-top'] = 0;
      }
      this.selectedLSmenuPositions = [];
      this.isDisableMoveButton = true;
      this.activeMapMove = false;
      this.activeLsMove = false;
      this.multipleMoveMenu.closeMenu();
    }
    if (this.selectedLSTrailBLrow.length > 0) {
      this.isindividualRowSaveforRE = false;
    } else {
      this.isindividualRowSaveforRE = true;
      this.disableLsAndMapNo();
    }
    if (this.selectedLSTrailBLrow.length == 1) {
      //this.enableDragging();
    } else {
      //this.disableDragging();
    }
  }
  disableLsAndMapNo() {
    this.retainedEarningsData.forEach(p => {
      if (p.id === 12) {
        p.children.forEach(s => {
          s.isMap = false;
          if (s.children) {
            s.children.forEach(sc => {
              if (sc.trialbal) {
                sc.trialbal.forEach(ssc => {
                  ssc.isLeadSheet = false;
                  ssc.isMap = false;
                })
              }
            });
          }
        });
      }
    });
  }
  changePosition(event, checkboxLevel) {
    const span: HTMLElement = document.querySelector('.test');
    span.style.left = '82px';
    if ((screen.availHeight - event.screenY - 35) >= this.lsMenuPopupHeight) {
      span.style.top = (event.pageY - 35) + 'px';
      span.style.bottom = "auto";
    } else {
      span.style.top = (event.pageY - this.lsMenuPopupHeight - 70) + 'px';
      span.style['margin-top'] = 0;
    }
    if (checkboxLevel != 'child2') {
      let menuStyles = {
        'left': span.style.left,
        'top': span.style.top,
        'bottom': span.style.bottom,
        'id': checkboxLevel.trailbalanceid,
        'overflow': "auto"
      }

      let matchFound = false;
      for (let i = 0; i < this.selectedLSmenuPositions.length; i++) {
        if (this.selectedLSmenuPositions[i].id === checkboxLevel.trailbalanceid) {
          this.selectedLSmenuPositions.splice(i, 1);
          i = this.selectedLSmenuPositions.length;
          matchFound = true;
        }
      }
      if (matchFound === false) {
        this.selectedLSmenuPositions.push(menuStyles);
      }
      let stylesElement = this.selectedLSmenuPositions[this.selectedLSmenuPositions.length - 1];
      if (stylesElement) {
        span.style.top = stylesElement.top;
        span.style.bottom = stylesElement.bottom;
        span.style.left = stylesElement.left;
      }
    }
    const menuDiv: HTMLElement = document.querySelector('#LSMAPDialog');
    if (menuDiv)
      menuDiv.style.display = 'flex';
  }

  //Get show or hide user permissions
  getShowHideUserPermissions(id) {
    const inputData = { "useraccountid": id };
    let inputString = JSON.stringify(inputData);
    const data = {
      "procedureName": "getshoworhideuserpermissions",
      "inputParameters": inputString
    }
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let apdata = [];
        let result = {};
        let keyName = "jjjjj";
        //let stringify = JSON.stringify(response);
        apdata = JSON.parse(response);
        apdata.forEach((x, i) => {
          // result[x.accesstypeitemname.replace(/ /g, '').toLowerCase()] = x.ufid ? true : false;
          if (x.accesstypeitemufi == 1) {
            keyName = 'accessdashboard';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 2) {
            keyName = 'editfirminformation';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 3) {
            keyName = 'billing';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 4) {
            keyName = 'exportdata';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 5) {
            keyName = 'addteammember';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 6) {
            keyName = 'modifyotherteammembers';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 7) {
            keyName = 'deleteteammember';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 8) {
            keyName = 'addclient';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 9) {
            keyName = 'viewclients';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 10) {
            keyName = 'modifyclients';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 11) {
            keyName = 'deleteclient';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 12) {
            keyName = 'viewengagements';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 13) {
            keyName = 'createengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 14) {
            keyName = 'modifyengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 15) {
            keyName = 'deleteengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 16) {
            keyName = 'archiveengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 17) {
            keyName = 'reopenengagement';
            result[keyName] = x.ufid ? true : false;
          }
          else if (x.accesstypeitemufi == 999) {
            keyName = 'viewmodifiengagement';
            result[keyName] = x.ufid ? true : false;
          }

        });

        this.sharedservice.accessPermissionsSubject.next(result);
        this.accessPermissions = result;
      }
    }, error => {
      console.log(error.error);
    });
  }
  /* getFSNotes() {
     this.isSelectAll = false;
     let jsonFileName = this.engagementId + "_" + this.selectedEngagementDetails.engagementName + '.json';
     const uploadObj = {
       "module": modules[16],
       "firmId": this.firmUserAcctId,
       "clientId": this.selectedEngagementDetails.clientfirmid,
       "engagementId": this.engagementData.engagementsid,
       "status": 0,
       "folderName": jsonFileName,
       "appSyncId": this.userLoginId,
       "extension": 'comp',
     }
     this.spinner.show();
     this.apiService.getJsonData(uploadObj).subscribe((response: any) => {
       if (response) {
         this.notes = !response ? [] : JSON.parse(response)
         console.log(this.notes)
         this.notes.forEach(ele => {
           ele['isExpand'] = false;
           ele['is_checked'] = false;
           if (ele.notes) {
             ele.notes.forEach(element => {
               element['is_checked'] = false;
             });
           }
         })

         this.spinner.hide();
       }
     }, (error) => {
       this.spinner.hide();
       this.notes = [];
     });
   }
   getLinkTo() {
     this.apiService.getBalanceSheetLink(this.engagementId, this.accYearEnd).subscribe(resp => {
       if (resp) {
         console.log(resp)
         this.getlinkarray = resp
       }
     })
   }
   getBalancesheet_Notes_Link_Obj() {
     for (let i = 0; i < this.retainedEarningsData.length; i++) {
       for (let j = 0; j < this.retainedEarningsData[i].children.length; j++) {
         for (let k = 0; k < this.retainedEarningsData[i].children[j].children.length; k++) {
           var indx = this.retainedEarningsData[i].children[j].children.findIndex(x => x.chldid == this.notesSRc.finsubgroupchildId)
           if (indx > -1) {
             return this.retainedEarningsData[i].children[j].children[indx];
           }
         }
       }
     }
   }
   get_NotesSrc_Obj(event) {
     for (let i = 0; i < this.getlinkarray.length; i++) {
       for (let j = 0; j < this.getlinkarray[i].child.length; j++) {
         if (this.getlinkarray[i].child[j].subchild.find(x => x.finsubgroupchildId == event.chldid)) {
           return this.getlinkarray[i].child[j].subchild.find(x => x.finsubgroupchildId == event.chldid)
         }
       }
     }
   }
   getCheckedNotes() {
     var selected_Records = [];
     this.notes.forEach(elem => {
       if (elem.is_checked) {
         if (elem.notes) {
           elem.notes.forEach(element => {
             selected_Records.push(element)
           });
         }
         else {
           selected_Records.push(elem)
         }
       }
       else if (elem.notes) {
         elem.notes.forEach(element => {
           if (element.is_checked) {
             selected_Records.push(element)
           }
         });
       }
     })
     return selected_Records
   }
   saveLinkNotes() {
     var selected_Records = [];
     selected_Records = this.getCheckedNotes();
     var previously_Selected = this.getBalancesheet_Notes_Link_Obj();
     var linksavearray = [];
     selected_Records.forEach(sobj => {
       var indx = -1;
       if (previously_Selected.notes) {
         indx = previously_Selected.notes.findIndex(x => x.engNoteId == sobj.engNoteId)
       }
       var obj = {
         "engagementsId": this.engagementId,
         "finSubgroupChildId": this.notesSRc.finsubgroupchildId,
         "submoduleId": this.submoduleId,
         "noteName": sobj.name,
         "noteId": sobj.id.toString(),
         "engNoteId": sobj.engNoteId.toString(),
         "isChanged": 0,
         "isDelete": 0,
         "loginId": this.userLoginId,
         "acctYear": this.accYearEnd,
         "workbookNotesId": indx > -1 ? previously_Selected.notes[indx].workbooknotesid : 0
       }
       linksavearray.push(obj)
     })
     var payload = {
       "save": linksavearray.length > 0 ? linksavearray : [{
         "engagementsId": this.engagementId,
         "finSubgroupChildId": this.notesSRc.finsubgroupchildId,
         "submoduleId": this.submoduleId,
         "noteName": '',
         "noteId": '',
         "engNoteId": '0',
         "isChanged": 0,
         "isDelete": 0,
         "loginId": this.userLoginId,
         "acctYear": this.accYearEnd,
         "workbookNotesId": 0
       }]
     }
     this.apiService.saveNotesBalanceSheet(payload).subscribe(resp => {
       if (resp && resp == 'Success') {
         this.toastr.success("Successfully Completed")
         this.refreshPreview();
         this.getretainedEarningsData("");
       }
     })
   }
   navigateToNotesToFs(id) {
     this.router.navigate(['/trial-balance-working-sheet/fs-notes'], { queryParams: { NoteId: id } });
   }
     resetNotesIs_Checked() {
       this.notes.forEach(ele => {
         ele.is_checked = false;
         if (ele.notes) {
           ele.notes.forEach(element => {
             element.is_checked = false;
           });
         }
       })
     }

     is_recordChecked(i, checkedType, j?) {
       if (checkedType == "category") {
         this.notes[i].is_checked = !this.notes[i].is_checked;
         if (this.notes[i].notes) {
           this.notes[i].notes.forEach(element => {
             element.is_checked = this.notes[i].is_checked == true ? true : false
           });
         }
         this.isSelectAll = this.notes.findIndex(x => x.is_checked == false) < 0 ? true : false
       } else if (checkedType == "subcategory") {
         this.notes[i].notes[j].is_checked = !this.notes[i].notes[j].is_checked;
         this.notes[i].is_checked = this.notes[i].notes.findIndex(x => x.is_checked == false) < 0 ? true : false
         this.isSelectAll = this.notes.findIndex(x => x.is_checked == false) < 0 ? true : false
       }
     }

   changednotesLinks(event?) {
     this.resetNotesIs_Checked()
     this.notesSRc = event ? this.get_NotesSrc_Obj(event) : this.notesSRc
     var balance_obj = event ? event : this.getBalancesheet_Notes_Link_Obj();
     this.disablegreenbutton = (balance_obj.notes && balance_obj.notes.length > 0) ? true : false
     if (balance_obj.notes) {
       for (let k = 0; k < this.notes.length; k++) {
         for (let i = 0; i < balance_obj.notes.length; i++) {
           if (this.notes[k].engNoteId == balance_obj.notes[i].engNoteId) {
             this.is_recordChecked(k, 'category');
           } else if (this.notes[k].notes) {
             for (let j = 0; j < this.notes[k].notes.length; j++) {
               if (this.notes[k].notes[j].engNoteId == balance_obj.notes[i].engNoteId) {
                 this.is_recordChecked(k, 'subcategory', j);
               }
             }
           }
         }
       }
     }
   }
   notesFSLinkingSelectionDialogFunc(child2?) {
     // this.resetNotesIs_Checked();
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = false;
     dialogConfig.autoFocus = true;
     dialogConfig.height = '100%';
     dialogConfig.position = { 'top': '53px', right: '0' };
     this.changednotesLinks(child2);
     const dialogRef = this.dialog.open(this.notesFSLinkingDialog, dialogConfig);
     dialogRef.afterClosed().subscribe();
   }
 */
  ngOnDestroy() {
    clearTimeout(this.userActivity);
    if (this.confirmLeadsheetSub) {
      this.confirmLeadsheetSub.unsubscribe();
    }
    if (this.sortSub) {
      this.sortSub.unsubscribe();
    }
    this.unAssignMapSub.unsubscribe();
    this.headerSubmoduleNameSub.unsubscribe();
    this.allSubscriptions && this.allSubscriptions.unsubscribe();
  }

  showFormattedNumber(data: number) {
    if (data > 0) {
      return data.toLocaleString('en-us');
    } else if (data < 0) {
      const absoluteNumber = data * -1;
      const formatted = absoluteNumber.toLocaleString('en-US');
      return '(' + formatted + ')';
    } else {
      return '-';
    }
  }

  // current and Previous
  getPreviousAndCurrentDate() {
    const data = this.workbookservice.getPreviousAndCurrentDate(this.yearEndDetails, this.selectedYearEnd);
    this.currentYear = data.currentYear;
    this.selectedYearTitle = data.selectedYearTitle;
    this.previousYear = data.previousYear;
  }
}
