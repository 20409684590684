import { DatePipe, Location } from '@angular/common';
import { Component, HostListener, Input, OnInit, signal, TemplateRef, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
// import { modules } from 'src/app/services/app-config';
import { Subject, Subscription } from 'rxjs';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/services/api.service';
import { idleTime, modules } from 'src/app/services/app-config';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { EncodingService } from '../../../../../services/encoding.service';
import { UserDetailModel } from 'countable@model';
import { AuthService } from 'src/app/services/auth.service';
import { CountableTimeZonePipe } from 'countable@helpers';
import { EngService } from 'src/app/services/eng.service';


@Component({
  selector: 'app-cover-page',
  templateUrl: './cover-page.component.html',
  styleUrls: ['./cover-page.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class CoverPageComponent implements OnInit {
  @ViewChild('textEditor') textEditor: TemplateRef<any>;
  config: any = {
    height: 250,
    //theme: 'modern',
    // powerpaste advcode toc tinymcespellchecker a11ychecker mediaembed linkchecker help
    plugins: 'print pagebreak print preview searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
    toolbar: 'print pagebreak | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent fontselect fontsizeselect | removeformat',
    image_advtab: true,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    toolbar_mode: 'sliding',
    menubar: true,
    statusbar: false,
    content_css: [
      '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
      '//www.tinymce.com/css/codepen.min.css'
    ]
  };
  date = new UntypedFormControl(new Date());
  serializedDate = new UntypedFormControl((new Date()).toISOString());
  yearEnd: any;
  businessName: any = '<h4>Business Name</h4>';
  financialYear: any = '<h5>FY 18-19</h5>';
  editorContent: any;
  editorContentCopy: any;
  coverContent: any;
  coverContentCpoy: any;
  selectedElement: any = '';
  dialogRef: any;
  coverImage: any = '/assets/images/logs/countable-logo.svg';
  imageFile: any;
  engagementID: any;
  documentDate: any = new Date();
  savedData: any;
  engagementClientID: any;
  loggedInUserData: UserDetailModel;
  bucketName: any;
  businessname: any;
  selectedEngagement: any = {};
  shortCutMenuDetails: any;
  public isTaxEngagement: WritableSignal<boolean> = signal<boolean>(false);
  
  @Input('editDeable') editDeable: boolean;
  isShowDraft: boolean = false;
  showDraftSub: Subscription;
  compilationReport = {
    'reportHeader': '',
    'topLeftContent': '',
    'letterBody': '',
    'bottomRightContent': '',
    'topImage': '',
    'bottomImage': '',
    'headerId': 0,
    'footerContent': '',
    'footerId': 0
  };
  permissionsSub: Subscription;
  accessPermissions: any;
  trackobj: { engagementsId: any; submoduleId: any; submoduleUuid: any; };
  userActivity;
  userInactiveSub: Subscription;
  userInactive: Subject<any> = new Subject();
  firmtemplate: any;
  mastertemplate: any;
  selectedEngagementTypeName: string;

  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:resize', ['$event'])
  @HostListener('window:keydown', ['$event'])
  refreshUserState() {
    clearTimeout(this.userActivity);
    if (localStorage.getItem('timerStatus') == 'Idle') {
      this.trackerService.startTimer('Active');
    }
    this.setTimeout();
  }

  constructor(public dialog: MatDialog, private sharedservice: SharedService, private apiService: ApiService, public toastr: ToastrService, private authService: AuthService,
    private spinner: NgxSpinnerService, public router: Router, private datePipe: DatePipe, private location: Location, private trackerService: TimeTrackerService, 
    private encodingService: EncodingService,private countableTimeZonePipe: CountableTimeZonePipe, private engService: EngService) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.trackerService.startTimer('Idle');
    });
  }

  ngOnInit() {
    this.shortCutMenuDetails = JSON.parse(localStorage.getItem('shortCutMenuDetails'));
    this.loggedInUserData = this.authService.getUserDetail();
    this.selectedEngagement = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    this.isTaxEngagement.set(this.isTaxTemplate());
    let businessname = this.selectedEngagement.businessname ? this.selectedEngagement.businessname : this.selectedEngagement.clientName ? this.selectedEngagement.clientName : '';
    this.businessname = businessname;
    this.bucketName = this.loggedInUserData.s3BucketName;
    var ducumentYearEnd = this.countableTimeZonePipe.transform(this.selectedEngagement.yearEnd, 'MMMM d, yyyy', 'UTC');
    if (this.loggedInUserData.origin_country_id == 226 || this.selectedEngagement.engagementtype.toLowerCase().startsWith('review') || this.isTaxEngagement()) {
      this.coverContent = `<div><h1 style="text-align: right;">${this.businessname}</h1></div>
    <div style="text-align: right;"><h6>${ducumentYearEnd}</h6></div>
    <div><h6 style="text-align: right;">Financial Statements</h6></div>`;
    } else {
      this.coverContent = `<div><h1 style="text-align: right;">${this.businessname}</h1></div>
    <div style="text-align: right;"><h6>${ducumentYearEnd}</h6></div>
    <div><h6 style="text-align: right;">Compiled Financial Information</h6></div>`;
    }
    this.coverContentCpoy = this.coverContent;
    this.editorContentCopy = this.coverContentCpoy;

    this.trackobj = {
      'engagementsId': this.selectedEngagement.engagementsid,
      'submoduleId': JSON.parse(localStorage.getItem('FsSubmoduleId')),
      'submoduleUuid': localStorage.getItem('UUID')
    };
    localStorage.setItem('timeTrackerObj', JSON.stringify(this.trackobj));
    this.trackerService.startTimer('Active');
    this.getJSON('engagement_report.json', true);
    let draft = JSON.parse((localStorage.getItem('showdraft')));
    if (draft) {
      this.isShowDraft = draft.isShowDraft ? draft.isShowDraft : '';
    }
    this.showDraftSub = this.sharedservice.showdraft.subscribe(res => {
      if (res) this.isShowDraft = res.isShowDraft;
    });

    this.permissionsSub = this.sharedservice.accessPermissionsSubject.subscribe(reponse => {
      if (reponse) {
        this.accessPermissions = reponse;
      }
    });

    if ((this.selectedEngagement?.pengagementtypeid && this.selectedEngagement.pengagementtypeid == 1) ||
      (this.selectedEngagement?.engagementtypeid && this.selectedEngagement.engagementtypeid == 1)) {
      this.selectedEngagementTypeName = "Compilation";
    } else if ((this.selectedEngagement?.pengagementtypeid && this.selectedEngagement.pengagementtypeid == 2) ||
      (this.selectedEngagement?.engagementtypeid && this.selectedEngagement.engagementtypeid == 2)) {
      this.selectedEngagementTypeName = "Review";
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), idleTime);
  }

  public getJSON(jsonFileName: string, isCallAgain: boolean) {
    this.engagementID = parseInt(localStorage.getItem('engagementID'));
    this.engagementClientID = parseInt(localStorage.getItem('engagementClientID'));
    const uploadObj = {
      'module': modules[6],
      'firmId': this.loggedInUserData.firmUserAcctId,
      'clientId': this.selectedEngagement.clientfirmid,
      'engagementId': this.selectedEngagement.engagementsid,
      'status': 0,
      'folderName': jsonFileName,
      'appSyncId': this.loggedInUserData.useracctid
    };
    this.spinner.show();
    this.apiService.getJsonData(uploadObj).subscribe((response: any) => {
      if (response) {
        this.savedData = this.parseData(response);
        if (this.savedData && this.savedData.coverPage) {
          this.businessName = this.savedData.coverPage.businessName ? this.savedData.coverPage.businessName : this.businessName;
          this.financialYear = this.savedData.coverPage.financialYear ? this.savedData.coverPage.financialYear : this.financialYear;
          this.documentDate = this.savedData.coverPage.date ? this.savedData.coverPage.date : this.datePipe.transform(this.selectedEngagement.yearEnd, 'MMMM d, y');
          this.editorContent = this.savedData.coverPage.coverpageContent ? this.savedData.coverPage.coverpageContent : this.coverContent;
          this.coverContent = this.editorContent;
          this.editorContentCopy = this.editorContent;
        } else {
          this.editorContent = this.coverContentCpoy;
          this.editorContentCopy = this.editorContent;
        }

        if (this.savedData?.compilationReport?.letterBody || this.savedData?.compilationReport?.para) {
          this.compilationReport = this.savedData.compilationReport;
        } else {
          this.getReportFirmTemplate('firm', 'calltwice');
        }
      } else {
        if (isCallAgain) {
          this.getJSON(this.engagementID + '_statement.json', false);
        } else {
          this.getReportFirmTemplate('firm', 'calltwice');
        }

      }



      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.savedData = {
        'coverPage': { 'logo': '', 'businessName': '', 'financialYear': '', 'date': '', 'coverpageContent': '' },
        'compilationReport': {
          'reportHeader': '',
          'topLeftContent': '',
          'letterBody': '',
          'bottomRightContent': '',
          'topImage': '',
          'bottomImage': '',
          'headerId': 0,
          'footerContent': '',
          'footerId': 0
        },
        'page3': { 'title1': '', 'title2': '' }
      };
    });
  }

  openEditor(elementName?) {
    if (this.shortCutMenuDetails.isenglock != 1) {
      if (this.router.url !== '/dashboard/ntrdashboard/FinancialStatement/doc/financialstatementexport') {

        if (this.editorContent) {
          this.editorContent = this.editorContentCopy;
        } else {
          this.editorContent = this.coverContentCpoy;
        }
        this.dialogRef = this.dialog.open(this.textEditor, { disableClose: true });
      }
    }
  }

  doneEditing() {
    this.dialogRef.close();
    this.saveJSON(true);
  }

  closeDialog() {
    if (this.editorContent) {
      this.coverContent = this.editorContentCopy;
    } else {
      this.coverContent = this.coverContentCpoy;
    }
    this.dialogRef.close();
  }

  uploadCoverImage(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imageFile = file;
      const reader = new FileReader();
      reader.onload = e => this.coverImage = reader.result;
      reader.readAsDataURL(file);
      this.saveCoverImage();
    }
  }

  saveCoverImage() {
    if (this.imageFile) {
      let formData: FormData = new FormData();
      let coverImageName = this.engagementID + '_' + this.imageFile.name;
      let s3signatureKey = 'profilepicture/fd/' + coverImageName;
      formData.append('signatureKey', s3signatureKey);
      formData.append('file', this.imageFile, coverImageName);
      this.apiService.saveProfileImageToS3(formData).subscribe(response => {
        if (response) {
          this.coverImage = 'https://s3.amazonaws.com/adinovisemailtemplateimage/profilepicture/fd/' + coverImageName;
          this.saveJSON();
        }
      }, error => {

      });
    }
  }

  dateChange() {
    this.saveJSON();
  }

  saveJSON(saveEdit?) {
    if (this.savedData) {
      if (typeof (this.savedData) == 'string') {
        this.savedData = [this.savedData];
      }
    } else {
      this.savedData = {
        'coverPage': { 'logo': '', 'businessName': '', 'financialYear': '', 'date': '', 'coverpageContent': '' },
        'compilationReport': {
          'reportHeader': '',
          'topLeftContent': '',
          'letterBody': '',
          'bottomRightContent': '',
          'topImage': '',
          'bottomImage': '',
          'headerId': 0,
          'footerContent': '',
          'footerId': 0
        },
        'page3': { 'title1': '', 'title2': '' }
      };
    }
    if (saveEdit) {
      this.spinner.show();
    }
    this.savedData.coverPage = {
      'logo': this.coverImage,
      'businessName': this.businessName,
      'financialYear': this.financialYear,
      'date': this.documentDate,
      'coverpageContent': this.editorContent
    };

    if (this.compilationReport) {
      this.savedData.compilationReport = this.compilationReport;
    }
    let jsonString = JSON.stringify(this.savedData);
    var blob = new Blob([jsonString], { type: 'text/json;charset=utf-8' });
    let formData: FormData = new FormData();
    let jsonFileName = 'engagement_report.json';
    /* let s3signatureKey = 'Engagements/' + this.loggedInUserData.firmUserAcctId + '_Firm/' + this.selectedEngagement.clientfirmid + '_Client/' + this.selectedEngagement.engagementsid + '_engagement/' + 'FinancialStatement'; */
    const uploadObj = JSON.stringify({
      'module': modules[6],
      'firmId': this.loggedInUserData.firmUserAcctId,
      'clientId': this.selectedEngagement.clientfirmid,
      'engagementId': this.selectedEngagement.engagementsid,
      'status': 0,
      'folderName': 'FinancialStatement',
      'appSyncId': this.loggedInUserData.useracctid,
      'extension': 'comp'
    });
    formData.append('upload', this.encodingService.enData(uploadObj, 'body'));
    formData.append('file', blob, jsonFileName);
    this.apiService.saveAndGetFileNamePath(formData).subscribe(response => {

      if (saveEdit) {
        this.dialogRef.close();
        this.toastr.success('Updated content successfully');
      }
      this.getJSON('engagement_report.json', true);
    }, error => {
      if (saveEdit) {
        this.dialogRef.close();
        this.toastr.error(error.error);
        this.spinner.hide();
      }
    });
  }

  showStatementPreview() {
    const angularRoute = this.location.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '');
    const newWindowUrl = domainAndApp + '/dashboard/ntrdashboard/FinancialStatement/doc/financialstatementexport';
    window.open(newWindowUrl, '_blank');
  }

  //NTR FS Preview
  ntrFsPreview() {
    const newWindowUrl = '/financial-statement/preview';
    window.open(newWindowUrl, '_blank', 'location=yes,height=768,width=1200,scrollbars=yes,status=yes');
  }

  parseData(data) {
    if (!data) {
      return {};
    }
    if (typeof data === 'object') {
      return data;
    }
    if (typeof data === 'string') {
      return JSON.parse(data);
    }
    return {};
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
    this.showDraftSub.unsubscribe();
    this.permissionsSub.unsubscribe();
  }

  getFirmcompilationreport() {
    this.engagementID = parseInt(localStorage.getItem('engagementID'));
    this.engagementClientID = parseInt(localStorage.getItem('engagementClientID'));
    let jsonFileName = 'engagement_report.json';
    const uploadObj = {
      "module": modules[5],
      "firmId": this.loggedInUserData.firmUserAcctId,
      "clientId": this.selectedEngagement.clientfirmid,
      "engagementId": this.selectedEngagement.engagementsid,
      "status": 0,
      "folderName": jsonFileName,
      "appSyncId": this.loggedInUserData.useracctid

    }
    this.spinner.show();
    this.apiService.getJsonData(uploadObj).subscribe((response: any) => {
      this.spinner.hide();
      if (response && this.parseData(response)?.compilationReport && (this.parseData(response)?.compilationReport?.letterBody || this.parseData(response)?.compilationReport?.para)) {
        this.compilationReport = this.parseData(response)?.compilationReport;
      } else {
        //if above two API's calls have no data, checking for older engagements in firm then master to pick the default report
        if (this.firmtemplate && this.firmtemplate.length) {
          const firmDefaultReport = this.checkingFrimTemplates();
          if (firmDefaultReport.length) {
            this.compilationReport = firmDefaultReport[0];
          } else {
            const masterDefaultTemplate = this.checkingMasterTemplates();
            if (masterDefaultTemplate.length) {
              this.compilationReport = masterDefaultTemplate[0];
            }
          }
        } else if (this.mastertemplate && this.mastertemplate.length) {
          const masterDefaultTemplate = this.checkingMasterTemplates();
          if (masterDefaultTemplate.length) {
            this.compilationReport = masterDefaultTemplate[0];
          }
        }
      }
    }, (error) => {
      this.spinner.hide();
      console.log('error', error)
    });
  }

  checkingFrimTemplates() {
    let foundDefaultReport = [];
    this.firmtemplate.forEach(c => {
      if (c?.reports && c.reports.length) {
        if (c.reports.filter(item => item.isDefault).length && !foundDefaultReport.length) {

          foundDefaultReport = c.reports.filter(item => item.isDefault);
        } else {
          if (c?.subcategories && c.subcategories.length) {
            c.subcategories.forEach(subcat => {
              if (subcat.reports.filter(item => item.isDefault).length && !foundDefaultReport.length) {

                foundDefaultReport = subcat.reports.filter(item => item.isDefault);
              }

            });

          }
        }

      } else {
        if (c?.subcategories && c.subcategories.length) {
          c.subcategories.forEach(subcat => {
            if (subcat.reports.filter(item => item.isDefault).length && !foundDefaultReport.length) {
              foundDefaultReport = subcat.reports.filter(item => item.isDefault);
            }


          });

        }
      }

    });

    return foundDefaultReport;

  }
  checkingMasterTemplates() {
    let foundDefaultReport = [];
    this.mastertemplate.map(c => {
      if (c?.reports && c.reports.length && (this.selectedEngagementTypeName === c.name) && c.reports.filter(item => item.isDefault)) {
        foundDefaultReport = c.reports.filter(item => item.isDefault);
      }
    });
    return foundDefaultReport;
  }

  // Fetches the firm/master template saved in S3 bucket
  getReportFirmTemplate(report_from, numberoftimes?) {
    var jsonFileName = report_from == 'firm' ? this.loggedInUserData.firmUserAcctId + "_FirmTemplate.json" : '';
    const uploadObj = {
      "module": modules[report_from == 'firm' ? 22 : (this.loggedInUserData.origin_country_id === 226 ? 24 : 21)],
      "firmId": this.loggedInUserData.firmUserAcctId,
      "clientId": this.selectedEngagement.clientfirmid,
      "status": 0,
      "folderName": jsonFileName,
      "appSyncId": this.loggedInUserData.useracctid,
      "extension": 'comp',
    }
    this.spinner.show();

    this.apiService.getJsonData(uploadObj).subscribe((response: any) => {

      var res = response ? JSON.parse(response) : [];
      report_from == 'firm' ? this.firmtemplate = res : this.mastertemplate = res;
      if (numberoftimes == 'calltwice') {
        this.getReportFirmTemplate('master');
      } else {
        this.getFirmcompilationreport();
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
    });
  }

  isTaxTemplate(): boolean {
    return this.engService.isTaxTemplate(this.selectedEngagement);
  }

}
