import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FirmModel, GenericResModel, UserModel, VideoTutorialsModel } from 'countable@model';
import { BehaviorSubject, delay, map, Observable, of, retryWhen, Subscription, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { AddressV2Model } from '../model/address-v2.model';
import { AiFirmModel } from '../model/billing/ai-luca.model';
import { EngCountModel } from '../model/eng/eng-count.model';
import { AddMemberModel } from '../model/firm/add-member.model';
import { FirmClientModel } from '../model/firm/firm-client.model';
import { FirmDeleteFeedBackModel } from '../model/firm/firm-delete-feedback';
import { FirmTeamCountModel } from '../model/firm/firm-team-count.model';
import { FirmUserModel } from '../model/firm/firm-user.model';
import { OnboardingResModel } from '../model/firm/onboarding-res.model';
import { OnboardingModel } from '../model/firm/onboarding.model';
import { AuthService } from './auth.service';
import { EncodingService } from './encoding.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class FirmService implements OnDestroy {

  private static readonly FIRM_KEY = 'firm-key';
  private static readonly URI_FIRM = environment.apiV2 + '/firm';
  private static readonly URI_ADDRESS = environment.apiV1 + '/address';

  public readonly subject: BehaviorSubject<FirmModel> = new BehaviorSubject(null);
  private readonly authenticationSubject: Subscription;
  public readonly aiInfo: BehaviorSubject<AiFirmModel> = new BehaviorSubject<AiFirmModel>(null);

  constructor(private http: HttpClient, private authService: AuthService, private encodingService: EncodingService) {
    if (localStorage.getItem(FirmService.FIRM_KEY) && localStorage.getItem(FirmService.FIRM_KEY) != 'undefined') {
      StorageService.applicationModel.firm = JSON.parse(localStorage.getItem(FirmService.FIRM_KEY));
      this.subject.next(StorageService.applicationModel.firm);
    }
    this.authenticationSubject = this.authService.subject.subscribe(status => {
      if (status) {
        this.refresh();
      }
    });
  }

  public refresh(): void {
    this.getFirm().subscribe();
    this.folderSize(true).subscribe();
    this.getEngCount(true).subscribe();
    this.getTeamCountForBilling(true).subscribe();
  }

  public getFirm(): Observable<FirmModel> {
    return this.http.get<FirmModel[]>(FirmService.URI_FIRM + '/get')
      .pipe(map(firms => {
        if (!(firms && Array.isArray(firms) && firms.length > 0)) {
          throw new Error('Please wait until we fetch the firm info');
        }
        return this.processFirms(firms);
      }), retryWhen(errors => errors.pipe(take(5), delay(2500))));
  }

  public inviteAll(invitees): Observable<GenericResModel<any>> {
    return this.http.put<GenericResModel<any>>(FirmService.URI_FIRM + '/invite-all', invitees, { headers: { responseType: 'json' } });
  }

  public getAddress(): Observable<AddressV2Model> {
    return this.http.get<AddressV2Model>(FirmService.URI_ADDRESS + '/firm');
  }

  public updateTeamMemberWithPermission(data: any): Observable<GenericResModel<boolean>> {
    return this.http.put<GenericResModel<boolean>>(FirmService.URI_FIRM + '/member', data);
  }

  public updateFirm(data: any): Observable<GenericResModel<boolean>> {
    return this.http.put<GenericResModel<boolean>>(FirmService.URI_FIRM + '/update', data)
      .pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public getEngCount(isRefresh?: boolean): Observable<EngCountModel> {
    isRefresh ? StorageService.applicationModel.EngCount = null : ''; // isRefresh=true to get latest data from API
    if (StorageService.applicationModel.EngCount) {
      return of(StorageService.applicationModel.EngCount);
    }
    return this.http.get<GenericResModel<EngCountModel>>(FirmService.URI_FIRM + '/count-eng')
      .pipe(map(res => {
        StorageService.applicationModel.EngCount = res.data;
        return StorageService.applicationModel.EngCount;
      }));
  }

  public getTeamCountForBilling(isRefresh?: boolean): Observable<FirmTeamCountModel> {
    isRefresh ? StorageService.applicationModel.firmTeamCount = null : ''; // isRefresh=true to get latest data from API
    if (StorageService.applicationModel.firmTeamCount) {
      return of(StorageService.applicationModel.firmTeamCount);
    }
    return this.http.get<GenericResModel<FirmTeamCountModel>>(FirmService.URI_FIRM + '/count-team')
      .pipe(map(res => {
        StorageService.applicationModel.firmTeamCount = res.data;
        return StorageService.applicationModel.firmTeamCount;
      }));
  }

  public deleteFirm(type: string, data: FirmDeleteFeedBackModel): Observable<GenericResModel<boolean>> {
    return this.http.delete<GenericResModel<boolean>>(FirmService.URI_FIRM + '/delete',
      {headers: {'X-Type': type}, body: data}).pipe(map(incoming => {
        if (incoming.status === 200 && type === 'next_billing_cycle') { this.refresh(); }
        return incoming;
      }));
  }

  public addTeamMember(data: AddMemberModel): Observable<GenericResModel<Boolean>> {
    return this.http.post<GenericResModel<Boolean>>(FirmService.URI_FIRM + '/member', data)
      .pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public deleteTeamMember(memberId: number): Observable<GenericResModel<Boolean>> {
    return this.http.delete<GenericResModel<Boolean>>(FirmService.URI_FIRM + '/member', { body: memberId })
      .pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public changeTeamMemberStatus(data: any): Observable<GenericResModel<Boolean>> {
    return this.http.patch<GenericResModel<Boolean>>(FirmService.URI_FIRM + '/member', data)
      .pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public onboardFirm(newData: OnboardingModel): Observable<GenericResModel<OnboardingResModel>> {
    return this.http.post<GenericResModel<OnboardingResModel>>(FirmService.URI_FIRM + '/onboarding', newData);
  }

  ngOnDestroy(): void {
    this.authenticationSubject && this.authenticationSubject.unsubscribe();
  }

  public deleteClient(data): Observable<any> {
    return this.http.delete(FirmService.URI_FIRM + '/client', {body: data});
  }

  public getSuperAdmins(): Observable<GenericResModel<any>> {
    return this.http.get<GenericResModel<FirmUserModel[]>>(FirmService.URI_FIRM + '/super-admin');
  }

  public undoDeletion(): Observable<GenericResModel<any>> {
    return this.http.put<GenericResModel<any>>(FirmService.URI_FIRM + '/undo-delete', null).pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public pauseNextBillingCycle(firmUserHash: string): Observable<GenericResModel<any>> {
    return this.http.put<GenericResModel<any>>(FirmService.URI_FIRM + '/pause-next-billing-cycle', firmUserHash)
      .pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public undoPause(): Observable<GenericResModel<any>> {
    return this.http.put<GenericResModel<any>>(FirmService.URI_FIRM + '/undo-pause', null)
      .pipe(map(incoming => {
        if (incoming.data) { this.refresh(); }
        return incoming;
      }));
  }

  public updateUserProfile(data: any): Observable<GenericResModel<any>> {
    return this.http.put<GenericResModel<any>>(FirmService.URI_FIRM + '/update-user-profile', data);
  }

  public switchFirm(firmHash: string): Observable<GenericResModel<UserModel>> {
    return this.http.put<GenericResModel<UserModel>>(FirmService.URI_FIRM + '/switch', firmHash);
  }

  public folderSize(isRefreshed: boolean): Observable<any> {
    if (isRefreshed) {
      StorageService.applicationModel.storageUsage = null;
    }
    const getStorageUsageFromService = (): Observable<any> => {
      if (StorageService.applicationModel.storageUsage) {
        return of(StorageService.applicationModel.storageUsage);
      }
    };
    if (StorageService.applicationModel.storageUsage) {
      return getStorageUsageFromService().pipe(map(usage => usage));
    } else {
      const data = {
        'id': this.authService.getUserDetail().firmUserAcctId,
        'status': 0,
        'appSyncId': this.authService.getUserDetail().useracctid
      };
      return this.http.post(environment.apiV1 + '/folder-size', this.encodingService.enData(data), { responseType: 'text' })
        .pipe(map(usage => this.processFolderUsage(usage)));
    }
  }

  public getFirmPartnerTeamAccess(): Observable<any> {
    return this.http.get(environment.apiV1 + '/team-access');
  }

  public updateClient(clientData: FirmClientModel): Observable<GenericResModel<boolean>> {
    return this.http.put<GenericResModel<boolean>>(FirmService.URI_FIRM + '/client', clientData);
  }

  private processFolderUsage(usage) {
    StorageService.applicationModel.storageUsage = {
      size: 0,
      measure: 'MB'
    };
    if (usage && !(usage == '-')) {
      StorageService.applicationModel.storageUsage = {
        size: Math.round(parseFloat(usage.split(' ')[0])),
        measure: (usage.toLowerCase().includes('kb') ? 'KB' : (usage.toLowerCase().includes('mb') ? 'MB' : 'GB'))
      };
    }
    return StorageService.applicationModel.storageUsage;
  }

  private processFirms(firms: FirmModel[]): FirmModel {
    if (!firms) {
      return;
    }
    StorageService.applicationModel.firms = firms;
    StorageService.applicationModel.hasMultipleFirms = false;
    if (firms.length === 1) {
      return this.processFirm(firms[0]);
    }
    StorageService.applicationModel.hasMultipleFirms = true;
    StorageService.applicationModel.firm = firms.filter(e => e.hash === localStorage.getItem('firm-hash')).pop();
    return this.propagateProcessedFirm();
  }

  private processFirm(firm: FirmModel): FirmModel {
    if (!firm) {
      return;
    }
    StorageService.applicationModel.firm = firm;
    return this.propagateProcessedFirm();
  }

  private propagateProcessedFirm(): FirmModel {
    localStorage.setItem(FirmService.FIRM_KEY, JSON.stringify(StorageService.applicationModel.firm));    
    this.subject.next(StorageService.applicationModel.firm);
    return StorageService.applicationModel.firm;
  }

  public getVideoTutorials(): Observable<GenericResModel<VideoTutorialsModel[]>> {
    return this.http.get<GenericResModel<VideoTutorialsModel[]>>(FirmService.URI_FIRM + '/tutorials');
  }

  public deactivateAi(): Observable<GenericResModel<boolean>> {
    const url = environment.apiV1 + '/luca/firm-settings';
    return this.http.delete<GenericResModel<boolean>>(url);
  }
}
