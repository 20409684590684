import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AvatarModule } from 'ngx-avatars';
import { EllipsisModule } from 'ngx-ellipsis';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxTinymceModule } from 'ngx-tinymce';
import { ToastrModule } from 'ngx-toastr';
import { AddNoteLinkPipe } from 'src/app/helpers/addNoteLink.pipe';
import { ListFilterPipe } from 'src/app/helpers/common-search-filter-pipe';
import { CurrentDatePipe } from 'src/app/helpers/current-date.pipe';
import { DiableAutofillDirective } from 'src/app/helpers/disable-autofill.directive';
import { MapFilterPipe } from 'src/app/helpers/map-filter.pipe';
import { MinusSignToParenthesisPipe } from 'src/app/helpers/minus-sign-to-parenthesis.pipe';
import { NumeriminusdecimalDirective } from 'src/app/helpers/numeriminusdecimal.directive';
import { SafePipe } from 'src/app/helpers/safeUrl.pipe';
import { SortByPipe } from 'src/app/helpers/sort-by.pipe';
import { TwoDigitDecimaNumberDirective } from 'src/app/helpers/two-digit-decimal-number.directive';
import { UniqueClientEmailValidatorDirective } from 'src/app/helpers/unique-client-email-validator.directive';
import { UniqueEmailValidatorDirective } from 'src/app/helpers/unique-email-validator.directive';
import { UniqueIdValidatorDirective } from 'src/app/helpers/unique-id-validator.directive';
import { UniqueLicenseValidatorDirective } from 'src/app/helpers/unique-license-validator.directive';
import { UniqueMapnoValidatorDirective } from 'src/app/helpers/unique-mapno-validator.directive';
import { AutoFocusDirective } from '../../helpers/auto-focus.directive';
import { CommentFilterPipe } from '../../helpers/comment-filter.pipe';
import { DateFormatPipe } from '../../helpers/dateFormat.pipe';
import { DragDropDirective } from '../../helpers/drag-and-drop-upload';
import { FolderFilterPipe } from '../../helpers/folder-filter.pipe';
import { MarkAsteriskDirective } from '../../helpers/markasterisk.directive';
import { CurrencyInputMaskDirective } from '../../helpers/number-format.directive';
import { SafeHtmlPipe } from '../../helpers/safehtml';
import { SummaryPipe } from '../../helpers/summary.pipe';
import { TimeAgoPipe } from '../../helpers/time-ago.pipe';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { UnsavedChangesDialogComponent } from '../dialogs/unsavedChangesDialog/unsavedChangesDialog.component';
import { EditableLabelComponent } from '../editableLabel/editableLabel.component';
import { EngagementsListGridComponent } from '../Engagements/engagement/engagements-list-grid/engagements-list-grid.component';
import { EngagementsListComponent } from '../Engagements/engagement/engagements-list/engagements-list.component';
import { BalanceSheetPreviewComponent } from '../Engagements/NTR_Dashboard/financial-statements/balance-sheet-preview/balance-sheet-preview.component';
import { CashflowPreviewComponent } from '../Engagements/NTR_Dashboard/financial-statements/cashflow-preview/cashflow-preview.component';
import { CoverPageComponent } from '../Engagements/NTR_Dashboard/financial-statements/cover-page/cover-page.component';
import { IncomeSheetPreviewComponent } from '../Engagements/NTR_Dashboard/financial-statements/income-sheet-preview/income-sheet-preview.component';
import { NotesToFinancialStatementsPreviewComponent } from '../Engagements/NTR_Dashboard/financial-statements/notes-to-financial-statements-preview/notes-to-financial-statements-preview.component';
import { StatementOfRetainedEarningComponent } from '../Engagements/NTR_Dashboard/financial-statements/statement-of-retained-earning/statement-of-retained-earning.component';
import { StockholderEquityPreviewComponent } from '../Engagements/NTR_Dashboard/financial-statements/stockholder-equity-preview/stockholder-equity-preview.component';
import { PageViewComponent } from '../firm/templates-dashboard/reports/page-view/page-view.component';
import { CustomEditorComponent } from '../index/custom-editor/custom-editor.component';
import { DailyLoadsComponent } from '../index/daily-loads/daily-loads.component';
import { FsEditorComponent } from '../index/fs-editor/fs-editor.component';
import { FsTableComponent } from '../index/fs-table/fs-table.component';
import { ModalBodyComponent } from '../index/notifications/custome-modal/modal-body.component';
import { ModalComponent } from '../index/notifications/modal.component';
import { TermsPrivacyComponent } from '../index/terms-privacy/terms-privacy.component';
import { CashFlowWorkStatementComponent } from '../index/trail-balance-working-sheet/Cash-flow/cash-flow-work-statement/cash-flow-work-statement.component';
import { PartnerCapitalHorizontalComponent } from '../index/trail-balance-working-sheet/partners-capital/partner-capital-horizontal/partner-capital-horizontal.component';
import { PartnerCapitalPreviewComponent } from '../index/trail-balance-working-sheet/partners-capital/partner-capital-preview/partner-capital-preview.component';
import { PartnerCapitalSettingsComponent } from '../index/trail-balance-working-sheet/partners-capital/partner-capital-settings/partner-capital-settings.component';
import { PartnerCapitalVerticalComponent } from '../index/trail-balance-working-sheet/partners-capital/partner-capital-vertical/partner-capital-vertical.component';
import { PartnersCapitalComponent } from '../index/trail-balance-working-sheet/partners-capital/partners-capital.component';
import { RetainedEarningsComponent } from '../index/trail-balance-working-sheet/retained-earnings/retained-earnings.component';
import { DeleteLeadsheetModalComponent } from '../index/trail-balance-working-sheet/working-balance-sheet/ls-dropdown/delete-leadsheet/delete-leadsheet-model.component';
import { LSDropDownComponent } from '../index/trail-balance-working-sheet/working-balance-sheet/ls-dropdown/ls-dropdown.component';
import { MapDropDownComponent } from '../index/trail-balance-working-sheet/working-balance-sheet/map-dropdown/map-dropdown.component';
import { UserNotificationsComponent } from '../notifications/notifications.component';
import { ProfileComponent } from '../profile/profile.component';
import { CountableRenameComponent } from '../remote-shared/countable-rename/countable-rename.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { FilePreviewDirective } from './file-preview/file-preview.directive';
import { GeneralPopupComponent } from './file-preview/general-popup/general-popup.component';
import { MicrosoftSdkComponent } from './file-preview/microsoft-sdk/microsoft-sdk.component';
import { FilePreviewPipe } from './file-preview/microsoft-sdk/microsoft-sdk.pipe';
import { PdfComponent } from './file-preview/pdf/pdf.component';
import { AutoExpandDirective } from 'src/app/helpers/autoexpand.directive';
import { CountableTimeZonePipe } from 'countable@helpers';
import { CountableMatDateComponent } from '../remote-shared/countable-mat-date/countable-mat-date.component';

// NGX Avatar Fixed colors
const avatarColors = ['#34D4D4', '#5C68F6', '#2ABAA2', '#F58B54', '#2A7CBA', '#91BD3A', '#AA2B96', '#745C97', '#3E95B1', '#3FC5F0', '#C2A441', '#C94270', '#2ABA64'];

@NgModule({
  declarations: [
    ProfileComponent, DragDropDirective, DailyLoadsComponent, SafeHtmlPipe, ListFilterPipe,
    BalanceSheetPreviewComponent, IncomeSheetPreviewComponent, CommentFilterPipe, AutoFocusDirective,
    CoverPageComponent, UserNotificationsComponent, ChangePasswordComponent,
    NotesToFinancialStatementsPreviewComponent,
    StockholderEquityPreviewComponent,
    RetainedEarningsComponent,
    CashFlowWorkStatementComponent,
    PartnersCapitalComponent,
    PartnerCapitalHorizontalComponent,
    PartnerCapitalVerticalComponent,
    PartnerCapitalPreviewComponent,
    PartnerCapitalSettingsComponent,
    EngagementsListComponent,
    EngagementsListGridComponent,
    SummaryPipe,
    FolderFilterPipe,
    TimeAgoPipe,
    DateFormatPipe,
    CurrentDatePipe,
    UniqueEmailValidatorDirective,
    UniqueClientEmailValidatorDirective,
    UniqueIdValidatorDirective,
    MapFilterPipe,
    FilePreviewPipe,
    UniqueMapnoValidatorDirective,
    StatementOfRetainedEarningComponent,
    MapDropDownComponent,
    LSDropDownComponent,
    DeleteLeadsheetModalComponent,
    CurrencyInputMaskDirective,
    TermsPrivacyComponent,
    MarkAsteriskDirective,
    DiableAutofillDirective,
    UniqueLicenseValidatorDirective,
    SafePipe, SortByPipe, AddNoteLinkPipe,
    ModalComponent,
    ModalBodyComponent,
    TwoDigitDecimaNumberDirective,
    PdfComponent,
    FilePreviewComponent,
    FilePreviewDirective,
    MicrosoftSdkComponent,
    GeneralPopupComponent,
    FsEditorComponent,
    FsTableComponent,
    PageViewComponent,
    EditableLabelComponent,
    CashflowPreviewComponent,
    CustomEditorComponent,
    UnsavedChangesDialogComponent,
    MinusSignToParenthesisPipe,
    NumeriminusdecimalDirective,
    AutoExpandDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTreeModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDividerModule,
    NgxSpinnerModule,
    NgSelectModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatDividerModule,
    ToastrModule,
    AvatarModule.forRoot({
      colors: avatarColors
    }),
    ToastrModule.forRoot(),
    NgxTinymceModule.forRoot({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/5.7.1/'
    }),
    EllipsisModule,
    ScrollingModule,
    DragDropModule,
    EditorModule,
    CountableRenameComponent,
    CountableMatDateComponent,
    CountableTimeZonePipe
  ],
  exports: [
    DailyLoadsComponent,
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatTreeModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    NgxSpinnerModule,
    NgSelectModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatBadgeModule,
    MatDividerModule,
    DragDropDirective,
    NgxTinymceModule,
    MatSlideToggleModule,
    MatDividerModule,
    ToastrModule,
    SafeHtmlPipe,
    ListFilterPipe,
    AvatarModule,
    BalanceSheetPreviewComponent, // imported component to use within multiple modules
    IncomeSheetPreviewComponent,  // imported component to use within multiple modules
    NotesToFinancialStatementsPreviewComponent,//imported component to use within multiple modules
    EllipsisModule,
    ProfileComponent,
    ChangePasswordComponent,
    UserNotificationsComponent, // Notifications - will Available for Partner, Staff and Client
    CommentFilterPipe,
    CoverPageComponent, // imported component to use within multiple modules
    StockholderEquityPreviewComponent,
    RetainedEarningsComponent, // imported component to use within multiple modules
    CashFlowWorkStatementComponent, // imported component to use within multiple modules
    PartnersCapitalComponent, // imported component to use within multiple modules
    PartnerCapitalHorizontalComponent, // imported component to use within multiple modules
    PartnerCapitalVerticalComponent, // imported component to use within multiple modules
    PartnerCapitalPreviewComponent, // imported component to use within multiple modules
    PartnerCapitalSettingsComponent, // imported component to use within multiple modules
    EngagementsListComponent, // imported component to use within multiple modules
    EngagementsListGridComponent, // imported component to use within multiple modules
    SummaryPipe,
    FolderFilterPipe,
    ScrollingModule,
    TimeAgoPipe,
    DateFormatPipe,
    UniqueEmailValidatorDirective,
    UniqueClientEmailValidatorDirective,
    UniqueIdValidatorDirective,
    MapFilterPipe,
    FilePreviewPipe,
    UniqueMapnoValidatorDirective,
    StatementOfRetainedEarningComponent,
    MapDropDownComponent,
    LSDropDownComponent,
    DeleteLeadsheetModalComponent,
    CurrencyInputMaskDirective,
    TermsPrivacyComponent,
    MarkAsteriskDirective,
    DiableAutofillDirective,
    UniqueLicenseValidatorDirective,
    SafePipe, SortByPipe, AddNoteLinkPipe,
    ModalComponent,
    ModalBodyComponent,
    TwoDigitDecimaNumberDirective,
    PdfComponent,
    FilePreviewComponent,
    FilePreviewDirective,
    FsEditorComponent,
    FsTableComponent,
    CustomEditorComponent,
    EditorModule,
    AutoFocusDirective,
    PageViewComponent,
    EditableLabelComponent,
    CashflowPreviewComponent,
    UnsavedChangesDialogComponent,
    MinusSignToParenthesisPipe,
    NumeriminusdecimalDirective,
    AutoExpandDirective,
    CountableMatDateComponent,
    CountableTimeZonePipe
  ]
})
export class SharedModule {
}
